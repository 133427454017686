body {
	font-family: 'Montserrat', sans-serif;
}

// common styles & helper classes
.cursor-pointer {
    cursor: pointer;
}

.input-group {
    align-items: center;
}

.add-input-feild {
    width: 100%;
    border-radius: 25px;
    border: none;

    &:focus {
        box-shadow: none;
        border: none;
    }
}

.bar_select {
	margin-right: 5px;
	
	label.input-group-text {
		border: none;
	    background-color: #fff;
	    font-size: 0.8rem;
	    color: #c3c3c3;
	    padding-right: 0;
	    padding-left: 0;
	}

	.select2-container--default {
        padding-left: 1px;
        min-width: 80px;
        max-width: 120px;
        width: max-content;
    }

    .assessment_select+.select2-container--default{
        min-width: 174px;
    }

	select {
		border: none;
	    font-size: 0.8rem;
		font-weight: 500;
		box-shadow: none;
	}
}

.bar_button{
    .note_validation{
        color: white;
        box-shadow: rgba(90, 202, 195, 0.5) 0px 10px 20px 0px;
        background-image: linear-gradient(225deg, rgb(114, 220, 214), rgb(62, 182, 173));
        border: 0px solid rgb(81, 196, 188);
        padding: 5px 10px;
        display: block;
        min-width: 90px;
        font-size: 10px;
        border: 1px solid rgb(209, 219, 226);
        text-align: center;
        border-radius: 15px;
        margin: 0px 4px;
        line-height: 14px;
        font-weight: bold;
    }
}


.select2-container--default .select2-selection--single {
    border: none;
    font-size: 1rem;
    box-shadow: none !important;
    outline: none !important;
}

.select2-dropdown--below {
    border: none;
    box-shadow: 0 5px 45px 0 rgba(153, 153, 153, 0.5);
}



.custom-select-student {


    .select2-container--focus {
        box-shadow: none;
        border: none;
    }

    .select2-selection--single {
        font-size: 0.8rem !important;
        padding: 0 !important;
    }

    .select2-results__option {
        font-size: .9rem;
        // padding: .7rem !important;
    }

    .select2-container {
        width: 151px !important;
    }
}



.select2-selection__rendered {
    color: #444;
    line-height: 28px;
    font-size: 0.9rem;
    padding: 0;
    font-weight: 500;
	    &:focus{
	    	box-shadow: none;
	    	border:none;
	    }
    }


.test {

    &.select2-dropdown {
        width: 55px !important;
    }

    // padding: .5rem 0 !important;
    // font-size: .8rem;
    &.select2-selection--single {
        .select2-selection__rendered {
            font-size: 0.9rem !important;
            font-weight: 500;
            padding-left: 0;
            padding-right: 0;

            &:focus {
                outline: none;
            }
        }

        .select2-selection__arrow b {
            margin-left: 0;
        }
    }



    .select2-selection--single {
        font-size: .8rem !important;
        text-align: center;

    }

    .select2-container--default .select2-selection--single {
        font-size: .8rem !important;
    }

    .select2-results__option {
        text-align: center;
        font-size: .8rem !important;
        padding: 14px 13px !important;
        font-weight: 500;
    }

    // .select2-container--default .select2-selection--single .select2-selection__arrow b{
    // 	border-color: #c3c3c3 transparent transparent transparent !important;
    //     border-style: solid;
    //     border-width: 7px 6px 0 6px;
    // }

    .select2-selection__arrow b {
        border-color: #c3c3c3 transparent transparent transparent !important;
        border-style: solid;
        border-width: 7px 7px 0 7px !important;
    }
}

.test2 {


    .select2-selection__rendered {
        font-size: 0.9rem !important;
        font-weight: 500;

        &:focus {
            outline: none;
        }
    }

    .select2-container {
        &:focus {
            outline: none;
        }

    }

    .select2-selection--single {
        font-size: .8rem !important;
        text-align: center;
    }

    .select2-container--default .select2-selection--single {
        font-size: .8rem !important;
    }

    .select2-results__option {
        text-align: center;
        font-size: 0.9rem !important;
        padding: 10px;
        text-align: center;
    }

    .select2-selection__arrow b {
        border-color: #000000 transparent transparent transparent !important;
        border-style: solid;
        border-width: 5px 5px 0 5px !important;
    }
}

@media only screen and (min-width: 1024px){
	.test{

		&.select2-dropdown {
	       left: -12px;
	   }
	}
	.evaluation_page .evaluation_container .e_single_table table .e_dropdown .dropdown-menu{
		left: -13px !important;
	}
}


.select2-container,
.selection,
.select2-selection {
    outline: none !important;
}

.evaluation_page .evaluation_container .e_single_table table .e_dropdown .dropdown-menu {
    min-width: 3.5rem !important;
    box-shadow: 0 0 20px rgba(51, 51, 51, 0.18);
    border: none;
}

.evaluation_page .evaluation_container .e_single_table table .e_dropdown .dropdown-menu .dropdown-item {
    justify-content: center;
}