@import "./variables.scss";

.btn{
    text-align: center;
    border:1px solid #d1dbe2;
    cursor: pointer;
    &.btn-circle{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border:0px!important;
        padding: 0px;
        *{
            font-size: 15px;
        }
        &.btn-sm{
            width: 30px;
            height: 30px;
            line-height: 30px;
        }
        &.btn-xs{
            width: 20px;
            height: 20px;
            line-height: 20px;
            *{
                font-size: 13px!important;
            }
        }
        
    }
    &.btn-rounded{
        border-radius: 20px;
        padding: 0px 10px;
        font-size: 14px;
        font-weight: 600;
        height: 30px;
        min-width: 100px;
        color: #3eb6ad;
        background:#fff;
        border:1px solid #d1dbe2;
        &:hover,&.active{
            color: #3eb6ad;
            border:1px solid #d1dbe2;
            background:#fff;
        }
    }
    &.btn-yellow{
        box-shadow: 0 18px 36px 0 rgba(239,194,91,0.5);
        background-image: linear-gradient(225deg,#fdd271,#d5a433);
        border: none;
        color: #fff;
        &:hover,&.active{
            box-shadow: 0 18px 36px 0 rgba(239,194,91,0.9);
            color: #fff;
            border: none;
            background-image: linear-gradient(225deg,#fdd271,#d5a433);
        }
    }
    &.btn-default{
        color: #3eb6ad;
        background:#fff;
        border:1px solid #d1dbe2;
        &.btn-circle{
            border:1px solid #3eb6ad!important;
        }
        &:hover,&.active{
            color: #3eb6ad;
            border:1px solid #d1dbe2;
            background:#fff;
        }
    }
    &.btn-danger{
        box-shadow: 0 10px 20px 0 rgba(220, 53, 69, 0.5);
        background-image: linear-gradient(225deg, #dc3545, #dc3545);
        color: #fff;
        border: none;
        &:hover,&.active{
            box-shadow: 0 10px 20px 0 rgba(220, 53, 69, 0.5);
            background-image: linear-gradient(225deg, #dc3545, #dc3545);
            color: #fff;
            border: none;
        }
    }
    &.btn-green{
        box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
        background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
        color: #fff;
        border: none;
        &:hover,&.active{
            box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.9);
            background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
            color: #fff;
            border: none;
        }
    }
    &.btn-green2{
        height: 34px;
        border-radius: 17px;
        box-shadow: 4px 12px 14px 0 rgba(91, 128, 126, 0.12);
        background-image: linear-gradient(152deg, rgba(62,182,173,1) 18%, rgba(74,207,197,1) 78%);
        color: #fff;
        border: none;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        &:hover,&.active{
            background-image: linear-gradient(150deg, #2ea79e, #5dc5c0);
            color: #fff;
            border: none;
        }
    }
    &.btn-default2{
        height: 34px;
        border-radius: 17px;
        box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.5);
        background: #edf3f6;
        color: #3eb6ad;
        border: none;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        &:hover,&.active{
            background-color: #e6f7ff;
            border: none;
        }
    }
    &.btn-default3{
        height: 34px;
        border-radius: 17px;
        box-shadow: 4px 12px 14px 0 rgba(91, 128, 126, 0.12);
        background: #edf3f6;
        border: solid 1px #43c0b7;
        color: #3db5ac;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        &:hover,&.active{
            background-color: #e6f7ff;
        }
    }
    &.btn-default-perp{
        height: 34px;
        border-radius: 17px;
        border: solid 1px #6170be;
        background-image: linear-gradient(93deg, rgba(226, 153, 232, 0.18) 18%, rgba(255, 255, 255, 0) 78%);
        color: #6170be;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        &:hover,&.active{
            box-shadow: 0px 0px 2px 0 rgba(0, 0, 0, 1);
            color: #6170be;
            border: solid 1px #6170be;
            background-image: linear-gradient(93deg, rgba(226, 153, 232, 0.18) 18%, rgba(255, 255, 255, 0) 78%);
        }
    }
    &.btn-gray{
        box-shadow: 0 10px 20px 0 rgba(210, 211, 211, 0.5);
        background-image: linear-gradient(225deg, #e4e8ec, #b1bfcd);
        color: #fff;
        border: 1px solid transparent;
        &:hover,&.active{
            box-shadow: 0 10px 20px 0 rgba(210, 211, 211, 0.9);
            color: #fff;
            border: 1px solid transparent;
            background-image: linear-gradient(225deg, #e4e8ec, #b1bfcd);
        }
    }
    &.btn-red{
        box-shadow: 0 10px 20px 0 rgba(251, 208, 185, 0.5);
        background-image: linear-gradient(140deg, #ffdac8, #ff6818);
        color: #fff;
        border: none;
        &:hover{
            box-shadow: 0 10px 20px 0 rgba(251, 208, 185, 0.9);
            color: #fff;
            border: none;
            background-image: linear-gradient(140deg, #ffdac8, #ff6818);
        }
    }
    &.btn-red2{
        height: 34px;
        border-radius: 17px;
        background-image: linear-gradient(140deg, #f66e7f, #ea3e5d);
        color: #fff;
        border: none;
        &:hover{
            box-shadow: 0 0 20px 0 rgba(251, 208, 185, 0.9);
            color: #fff;
            border: none;
            background-image: linear-gradient(140deg, #f66e7f, #ea3e5d);
        }
    }
    &.btn-close-modal{
        position: absolute;
        top: 0;
        right: 0;
        z-index: 5;
    }
    .close_ico{
        font-family: montserratRegular;
        font-size: 38px;
        font-weight: normal;
        line-height: 1;
        text-shadow: 0 1px 0 #fff;
    }
    
}


.text-green{
    color: #5acac3;
}

.nav-link.active{
    @extend .btn-green
}

.dropdown .btn-rounded{
    color: inherit;
    position: relative;
    line-height: 15px;
    padding: 5px 10px;
    width:100%;
    text-align: left;
    &:hover{
        color: inherit;
    }
    &:after{
        content:none;
    }
    i.fa{
        position: absolute;
        right:5px;
        background: #cdd6dc;
        color:#fff;
        border-radius: 50%;
        padding: 2px;
        margin-top: -2px;
    }
}

.search_container{
    width: 100%;
    margin-top: 15px;
    display: flex;
    background-color: #f8f8f8;
    border: solid 0.5px #b1bfcd;
    border-radius: 10em;
    padding: 0.15em 0.8em 0.15em 0.1em;
    input{
        width: 100%;
        border: 0;
        background: transparent;
        padding: 0;
        margin: 0;
        vertical-align: top;
        padding-left: 12px;
        font-size: 13px;
    }
}

.no-border{
    border:none!important;
}


.form-control{
    height: 30px;
    &.is-invalid{
        background:none;
    }
}

form {
    .form-group{
        position:relative;
        .error_form{
            bottom: 0px;
            transform: translateY(-6px);
        }
    }
    .error_form{
        position: absolute;
        left: 100%;
        bottom: 1rem;
        z-index: 1000;
        object-fit: contain;
        box-shadow: 0 5px 45px 0 rgba(153,153,153,0.5);
        background-color: #ffffff;
        border-radius: 5px;
        padding: 5px 10px;
        width: 12vw;
        &.left{
            left: 0px;
            transform: translateX(-100%);
            &:after{
                transform: scaleX(-1);
                right: -0.8vw;  
            }
        }
        &.bottom{
            bottom:unset;
            left:50%;
            transform: translateX(-50%);
            top:100%;
            &:after{
                right: 50%;
                transform:translateX(50%);
                bottom:100%;
                border-color: transparent transparent #ffffff transparent;
            }
        }
        &.top{
            bottom:100%;
            left:50%;
            transform: translateX(-50%);
            &:after{
                right: 50%;
                transform:translateX(50%);
                bottom: -11px;
                border-color: #ffffff transparent transparent transparent;
            }
        }
        &:after{
            content: "";
            position: absolute;
            right: 100%;
            margin-top: -6px;
            border-width: 0.4vw;
            border-style: solid;
            border-color: transparent #ffffff transparent transparent;
            bottom: 7px;
        }
        span{
            opacity: 0.8;
            font-size: 13px;
            color: #f45656;
        }
    }
}

label.btn{
    line-height: 30px;
    input[type=file]{
        position:absolute;
    }
}

.bootstrap-select .btn.dropdown-toggle:after{
    vertical-align: .255em;
}

.text_overflow{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.statistics_exam .modal-dialog{
    max-width: 90%
    
}

.container-cms{
    /*height: calc(100vh - 200px);*/
    @media (min-width: 767px){
        margin: 20px 20px!important;
    }
    @media (min-width: 1200px){
        margin: 20px 30px!important;
    }

    @media (max-width: 767px){
        height: calc( 100vh - 144px);
    }
    .optiscroll-vtrack, .optiscroll-htrack{
        background: rgba(25, 86, 197, 0.3);
    }
    .item-list{
        margin:0px;
        *{
            font-size: 0.625vw;
        }
        h4{
            font-size: 1vw;
        }
        &.items-8{
            .item-block{    
                @media (min-width: $desktop) {
                    max-width: 11.8%;
                    flex: 11.8%;
                    margin: 0px 0.4% 12px 0.4%; 
                    &:nth-child(-n+1), &:nth-child(8n+1){
                        margin-left:0px;
                    }
                    &:nth-child(7n+1):not(:first-child){
                        margin-right:0px;
                    }
                }
                @media (min-width: $tablet) and (max-width: $desktop) {
                    max-width: 19.2%;
                    flex: 19.2%;
                    margin:0px 0.5% 12px 0.5%; 
                    &:nth-child(-n+1), &:nth-child(6n+1){
                        margin-left:0px;
                    }
                    &:nth-child(5n+1):not(:first-child){
                        margin-right:0px;
                    }
                }
                @media (max-width: $tablet) {
                    max-width: 49%;
                    flex: 49%;
                    margin-right: 1%; 
                }
            }
        }
        &.items-6{
            .item-block{    
                @media (min-width: $desktop) {
                    max-width: 16%;
                    flex: 16%;
                    max-height: 239px;
                    margin: 0px 0.4% 12px 0.4%; 
                    &:nth-child(6n+1){
                        margin-left:0px;
                    }
                    &:nth-child(6n){
                        margin-right:0px;
                    }
                }
                @media (min-width: $tablet) and (max-width: $desktop) {
                    max-width: 23.5%;
                    flex: 23.5%;
                    margin: 0px 1% 12px 1%; 
                    &:nth-child(4n+1){
                        margin-left:0px;
                    }
                    &:nth-child(4n){
                        margin-right:0px;
                    }
                }
                @media (max-width: $tablet) {
                    max-width: 49%;
                    flex: 49%;
                    margin: 0px 1% 12px 1%; 
                    &:nth-child(2n+1){
                        margin-left:0px;
                    }
                    &:nth-child(2n){
                        margin-right:0px;
                    }
                }
            }
        }
        
        .item-block{
            min-height: 230px;
            object-fit: contain;
            border-radius: 4px;
            background-color: #ffffff;
            box-shadow: 0 5px 10px 0 rgba(46,104,208,0.1);
            text-align: center;
            padding-top: 70px;
            margin-bottom: 25px;
            position: relative;

            .item-img{
                margin:auto;
                img{
                    width:80px;
                    height:80px;
                    border-radius: 50%;
                    &.noradius{
                        box-shadow: 0 5px 10px 0 rgba(46,104,208,0.1) !important;
                    }
                }
            }
            .item-img-journal{
                margin:auto;
                img{
                    width:80px;
                    height:80px;
                    // border-radius: 50%;
                    &.noradius{
                        box-shadow: 0 5px 10px 0 rgba(46,104,208,0.1) !important;
                    }
                }
            }
            &.exam_item{
                padding-top: 40px; 
                &.exam_parent_item{
                    border: 1px solid red
                }
                .stat_icon{
                    position: absolute;
                    top: 15px;
                    right: 15px;
                    &.mr{
                        right: 40px;
                    }
                    i{
                        color: rgb(182, 195, 208);
                        font-size: 16px;
                        cursor: pointer;
                    }
                }
                .item-img{
                    img{
                        width: 120px;
                    }
                }
                .corrected{
                    color: #5acac3;
                    margin-top: 20px;
                    display: block;
                    font-weight: bold;
                }
                .not_passed{
                    color: #e92727;
                    margin-top: 20px;
                    display: block;
                    font-weight: bold;
                }
            }

            .btn-share-accept,.btn-share-refuse{
                position:absolute;
                bottom:5px;
                /*display:none;*/
            }
            .btn-share-accept{
                left:25%    
            }
            .btn-share-refuse{
                right:25%    
            }

            &.challenge_item{
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                background-color: #fff;
                padding: 0.5rem 0.7rem;
                border-radius: 5px;
                box-shadow: 0 5px 48px 0 rgba(46, 104, 208, 0.1);
                height: 255px;
                img.medal {
                    width: 40%;
                    height: 80px;
                }
    
                .c_title {
                    display: block;
                    font-size: 0.85rem;
                    font-weight: 600;
                    margin-bottom: 0.2rem;
                }
    
                .c_level {
                    font-size: 0.8rem;
                    display: block;
                    width: 100%;
                    text-align: center;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
    
                    .level {
                        color: $primary_gray;
                    }
                }
    
                .grades {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    font-size: 0.75rem;
                    text-align: center;
                    &.title{
                        display: block;
                    }
                }

                .all_btn {
                    margin: 0.8rem 0;

                    .btn {
                        text-transform: uppercase;
                    }
                }
    
                .c_footer {
                    width: 100%;
                    text-align: right;
                    position: absolute;
                    bottom: 10px;
                    padding: 0 0.5rem;
    
                    .c_btn {
                        button {
                            font-size: 0.75rem;
                        }
                    }
                }
    
                &.all,
                &.group {
                    .all_btn {
                        margin: 0.8rem 0;
    
                        .btn {
                            text-transform: uppercase;
                            font-size: 0.75rem;
                        }
                    }
                }
                &.school{
    
                    .c_title {
                        margin-top: 15px;
                    }
        
                    .c_level {
                        margin-top: 10px;
                    }
        
                    .grades {
                        margin-top: 15px;
                    }
                }
            }

            &.exercise_item {
                &.exercise_kls_item{
                    padding-top: 20px;    
                    .item_name{
                        height: 115px;
                        margin-top:10px;
                    }
                }
                .badge{
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    border-radius: 50%;
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    padding: 0px;
                }
                padding-top: 50px;
                .item_name{
                    white-space: pre-wrap;
                    height: 40px;
                    font-size: 11px;
                }
                .exercise-img{
                    width: 90px;
                    height: 90px;
                    border:1px solid transparent;
                    border-radius: 50%;
                    margin:auto;
                    img{
                        width: 80px;
                        height: 80px;
                        margin-top: 4px;
                    }
                    &.act{
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        box-shadow: 1px 1px 9px 1px #adabab;
                        margin-bottom: 10px;
                        img{
                            width: 100%;
                            height: 100%;
                            padding: 0px;
                            margin: 0px;
                        }
                    }
                }
                border-bottom:5px solid transparent;
                &.activity_theme_item{
                    .item_name{
                        height: inherit !important;
                    }
                }
            }
            &.asset_item{
                border-bottom:5px solid transparent;
                padding-top: 60px;
                .item_name{
                    margin-top: 10px;    
                }
                .item_namee{
                    margin-top: 20px;
                    &:hover{
                        display:block;  
                      }
                }
                &.selected{
                    opacity: 0.5;
                }
            }
            &.report_item{
                border-bottom:5px solid transparent;
                padding-top: 60px;
                .report_icon{
                    font-size: 30px;
                    margin-bottom: 5px;
                    color: #5acac3;
                    i{
                        font-size: 30px;
                    }
                }
                .report_date{
                    margin-top: 10px;  
                    font-size: 13px;  
                }
                .report_object{
                    margin-top: 5px;  
                    font-size: 13px;  
                }
                .report_creator{
                    margin-top: 5px; 
                    font-size: 13px;   
                }
            }
            &.behavior_item{
                border-bottom:5px solid transparent;
                padding-top: 60px;
                .behavior_icon{
                    font-size: 30px;
                    margin-bottom: 5px;
                    color: #5acac3;
                    i{
                        font-size: 30px;
                    }
                }
                .behavior_date{
                    margin-top: 10px;  
                    font-size: 13px;  
                }
                .behavior_object{
                    margin-top: 5px;  
                    font-size: 13px;  
                }
                .behavior_creator{
                    margin-top: 5px; 
                    font-size: 13px;   
                }
                .assurance_status{
                    border-radius: 10px;
                    color: white;
                    font-weight: bold;
                    margin: 10px;
                }
            }
            &.folder_item{
                .item_name{
                    margin-top: 10px;    
                }
            }
            &.course_item,
            &.formation_item{
                // padding-top: 50px;
                padding-top: 40px;
                &.notifred{
                    position: absolute;
                    right: -7px;
                    top: -7px;
                    background: #d33f29;
                    width: 20px;
                    height: 20px;
                    border-radius: 12px;
                    font-size: 10px;
                    font-weight: 500;
                    line-height: 20px;
                    color: black;
                }
                .item_name{
                    white-space: pre-wrap;
                    margin-top: 10px;
                    // height: 40px;
                    // /*height: 27px;*/
                }
            }
            &.book_item{
                // padding-top: 50px;
                padding-top: 40px;
                &.notifred{
                    position: absolute;
                    right: -7px;
                    top: -7px;
                    background: #d33f29;
                    width: 20px;
                    height: 20px;
                    border-radius: 12px;
                    font-size: 10px;
                    font-weight: 500;
                    line-height: 20px;
                    color: black;
                }
                .item_name{
                    white-space: pre-wrap;
                    // height: 40px;
                    // /*height: 27px;*/
                }
            }
            
            .item_name{
                width: 90%;
                margin: auto;
                font-size: 0.875vw;
                font-weight: 600;
                line-height: 20px;
                @extend .text_overflow;
            }
            .item_n{
                width: 90%;
                margin: auto;
                font-size: 10px;
                font-weight: 600;
                @extend .text_overflow;
            }
            .item_info{
                position: absolute;
                bottom: 3px;
                left: 0px;
                width: 100%;
                padding: 0% 5%;
                @extend .text_overflow;
                &.top{
                    bottom: 17px;
                }
            }
            .add_item{
                width: 70px;
                height: 70px;
                line-height: 70px;
                font-size: 60px;
                margin-bottom: 20px;
                @extend .btn-yellow;
            }
        }
    }
    /*&.parent{
        margin-top: -30px !important;
        height: 100vh;
    }*/
}


.item-list.list_books{
    margin:0px;
    *{
        font-size: 0.625vw;
    }
    h4{
        font-size: 1vw;
    }
    .items-8{
        width: 100%;
        display: flex;
        .item-block{    
            @media (min-width: $desktop) {
                max-width: 11.8%;
                flex: 11.8%;
                margin: 0px 0.4% 12px 0.4%; 
                &:nth-child(-n+1), &:nth-child(8n+1){
                    margin-left:0px;
                }
                &:nth-child(7n+1):not(:first-child){
                    margin-right:0px;
                }
            }
            @media (min-width: $tablet) and (max-width: $desktop) {
                max-width: 19.2%;
                flex: 19.2%;
                margin:0px 0.5% 12px 0.5%; 
                &:nth-child(-n+1), &:nth-child(6n+1){
                    margin-left:0px;
                }
                &:nth-child(5n+1):not(:first-child){
                    margin-right:0px;
                }
            }
            @media (max-width: $tablet) {
                max-width: 49%;
                flex: 49%;
                margin-right: 1%; 
            }
        }
    }
    .items-6{
        width: 100%;
        display: flex;
        .item-block{    
            @media (min-width: $desktop) {
                max-width: 16%;
                flex: 16%;
                max-height: 239px;
                margin: 0px 0.4% 12px 0.4%; 
                &:nth-child(6n+1){
                    margin-left:0px;
                }
                &:nth-child(6n){
                    margin-right:0px;
                }
            }
            @media (min-width: $tablet) and (max-width: $desktop) {
                max-width: 23.5%;
                flex: 23.5%;
                margin: 0px 1% 12px 1%; 
                &:nth-child(4n+1){
                    margin-left:0px;
                }
                &:nth-child(4n){
                    margin-right:0px;
                }
            }
            @media (max-width: $tablet) {
                max-width: 49%;
                flex: 49%;
                margin: 0px 1% 12px 1%; 
                &:nth-child(2n+1){
                    margin-left:0px;
                }
                &:nth-child(2n){
                    margin-right:0px;
                }
            }
        }
    }
    
    .item-block{
        min-height: 230px;
        object-fit: contain;
        border-radius: 4px;
        background-color: #ffffff;
        box-shadow: 0 5px 10px 0 rgba(46,104,208,0.1);
        text-align: center;
        padding-top: 70px;
        margin-bottom: 25px;
        position: relative;

        .item-img{
            margin:auto;
            img{
                width:80px;
                height:80px;
                border-radius: 50%;
                &.noradius{
                    box-shadow: 0 5px 10px 0 rgba(46,104,208,0.1) !important;
                }
            }
        }
        .item-img-journal{
            margin:auto;
            img{
                width:80px;
                height:80px;
                // border-radius: 50%;
                &.noradius{
                    box-shadow: 0 5px 10px 0 rgba(46,104,208,0.1) !important;
                }
            }
        }
        &.exam_item{
            padding-top: 40px; 
            .stat_icon{
                position: absolute;
                top: 15px;
                right: 15px;
                &.mr{
                    right: 40px;
                }
                i{
                    color: rgb(182, 195, 208);
                    font-size: 16px;
                    cursor: pointer;
                }
            }
            .item-img{
                img{
                    width: 120px;
                }
            }
            .corrected{
                color: #5acac3;
                margin-top: 20px;
                display: block;
                font-weight: bold;
            }
            .not_passed{
                color: #e92727;
                margin-top: 20px;
                display: block;
                font-weight: bold;
            }
        }

        .btn-share-accept,.btn-share-refuse{
            position:absolute;
            bottom:5px;
            /*display:none;*/
        }
        .btn-share-accept{
            left:25%    
        }
        .btn-share-refuse{
            right:25%    
        }

        &.challenge_item{
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            background-color: #fff;
            padding: 0.5rem 0.7rem;
            border-radius: 5px;
            box-shadow: 0 5px 48px 0 rgba(46, 104, 208, 0.1);
            height: 255px;
            img.medal {
                width: 40%;
                height: 80px;
            }

            .c_title {
                display: block;
                font-size: 0.85rem;
                font-weight: 600;
                margin-bottom: 0.2rem;
            }

            .c_level {
                font-size: 0.8rem;
                display: block;
                width: 100%;
                text-align: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                .level {
                    color: $primary_gray;
                }
            }

            .grades {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                text-align: center;
                display: flex;
                flex-direction: column;
                font-size: 0.75rem;
                text-align: center;
                &.title{
                    display: block;
                }
            }

            .all_btn {
                margin: 0.8rem 0;

                .btn {
                    text-transform: uppercase;
                }
            }

            .c_footer {
                width: 100%;
                text-align: right;
                position: absolute;
                bottom: 10px;
                padding: 0 0.5rem;

                .c_btn {
                    button {
                        font-size: 0.75rem;
                    }
                }
            }

            &.all,
            &.group {
                .all_btn {
                    margin: 0.8rem 0;

                    .btn {
                        text-transform: uppercase;
                        font-size: 0.75rem;
                    }
                }
            }
            &.school{

                .c_title {
                    margin-top: 15px;
                }
    
                .c_level {
                    margin-top: 10px;
                }
    
                .grades {
                    margin-top: 15px;
                }
            }
        }

        &.exercise_item {
            &.exercise_kls_item{
                padding-top: 20px;    
                .item_name{
                    height: 115px;
                    margin-top:10px;
                }
            }
            .badge{
                position: absolute;
                top: 10px;
                left: 10px;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                line-height: 20px;
                padding: 0px;
            }
            padding-top: 50px;
            .item_name{
                white-space: pre-wrap;
                height: 40px;
                font-size: 11px;
            }
            .exercise-img{
                width: 90px;
                height: 90px;
                border:1px solid transparent;
                border-radius: 50%;
                margin:auto;
                img{
                    width: 80px;
                    height: 80px;
                    margin-top: 4px;
                }
                &.act{
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    box-shadow: 1px 1px 9px 1px #adabab;
                    margin-bottom: 10px;
                    img{
                        width: 90px;
                        height: 90px;
                        padding: 10px;
                    }
                }
            }
            border-bottom:5px solid transparent;
            &.activity_theme_item{
                .item_name{
                    height: inherit !important;
                }
            }
        }
        &.asset_item{
            border-bottom:5px solid transparent;
            padding-top: 60px;
            .item_name{
                margin-top: 10px;    
            }
            .item_namee{
                margin-top: 20px;
                &:hover{
                    display:block;  
                    }
            }
            &.selected{
                opacity: 0.5;
            }
        }
        &.report_item{
            border-bottom:5px solid transparent;
            padding-top: 60px;
            .report_icon{
                font-size: 30px;
                margin-bottom: 5px;
                color: #5acac3;
                i{
                    font-size: 30px;
                }
            }
            .report_date{
                margin-top: 10px;  
                font-size: 13px;  
            }
            .report_object{
                margin-top: 5px;  
                font-size: 13px;  
            }
            .report_creator{
                margin-top: 5px; 
                font-size: 13px;   
            }
        }
        &.behavior_item{
            border-bottom:5px solid transparent;
            padding-top: 60px;
            .behavior_icon{
                font-size: 30px;
                margin-bottom: 5px;
                color: #5acac3;
                i{
                    font-size: 30px;
                }
            }
            .behavior_date{
                margin-top: 10px;  
                font-size: 13px;  
            }
            .behavior_object{
                margin-top: 5px;  
                font-size: 13px;  
            }
            .behavior_creator{
                margin-top: 5px; 
                font-size: 13px;   
            }
            .assurance_status{
                border-radius: 10px;
                color: white;
                font-weight: bold;
                margin: 10px;
            }
        }
        &.folder_item{
            .item_name{
                margin-top: 10px;    
            }
        }
        &.course_item,
        &.formation_item{
            // padding-top: 50px;
            padding-top: 40px;
            &.notifred{
                position: absolute;
                right: -7px;
                top: -7px;
                background: #d33f29;
                width: 20px;
                height: 20px;
                border-radius: 12px;
                font-size: 10px;
                font-weight: 500;
                line-height: 20px;
                color: black;
            }
            .item_name{
                white-space: pre-wrap;
                margin-top: 10px;
                // height: 40px;
                // /*height: 27px;*/
            }
        }
        &.book_item{
            // padding-top: 50px;
            padding-top: 40px;
            &.notifred{
                position: absolute;
                right: -7px;
                top: -7px;
                background: #d33f29;
                width: 20px;
                height: 20px;
                border-radius: 12px;
                font-size: 10px;
                font-weight: 500;
                line-height: 20px;
                color: black;
            }
            .item_name{
                white-space: pre-wrap;
                // height: 40px;
                // /*height: 27px;*/
            }
        }
        
        .item_name{
            width: 90%;
            margin: auto;
            font-size: 0.875vw;
            font-weight: 600;
            @extend .text_overflow;
        }
        .item_n{
            width: 90%;
            margin: auto;
            font-size: 10px;
            font-weight: 600;
            @extend .text_overflow;
        }
        .item_info{
            position: absolute;
            bottom: 3px;
            left: 0px;
            width: 100%;
            padding: 0% 5%;
            @extend .text_overflow;
            &.top{
                bottom: 17px;
            }
        }
        .add_item{
            width: 70px;
            height: 70px;
            line-height: 70px;
            font-size: 60px;
            margin-bottom: 20px;
            @extend .btn-yellow;
        }
    }
}

@media (max-width: 767.98px){
    .custom_modal {
        .modal-dialog{
            height:calc(100% - 1rem);
            .modal-content{
                height: 100%;
            }
        }
    }
}

.responsive_modal{
    .modal-dialog{
        height:80%;
        .modal-content{
            height:100%;
        }
    }
    &.concened_persons_discipline{
        z-index: 1111050;
        background: #7076839e;
    }
}

.custom_modal {
    &.simulate_modal{
        .modal-dialog-centered{
            width:1000px;
            max-width: 1000px !important;
        }
    }
    &.bulletins_params{
        >div{
            max-width: 400px !important;
        }
    }
    &.diagnostic_test_report{
        >div{
            max-width: 100% !important;
            width: 80%;
            height: 90%;
            .modal-content{
                height: 100%;
                >div{
                    overflow: hidden;
                }
            }
        }
    }
    &.diagnostic_test_report_students{
        >div{
            max-width: 500px !important;
            width: 500px;
            height: 90%;
            .modal-content{
                height: 100%;
                >div{
                    overflow: hidden;
                }
            }
        }
    }

    &.top_modal{
        z-index:100000
    }
    &.math_keyboard{
        z-index: 100000000000;
    }
    .modal-content{
        border: 0px;
        border-radius: 20px;
        box-shadow: 0 5px 45px 0 rgba(46,104,208,0.1);
    }
    h2{
        font-size: 18px;
        font-weight: 500;
        margin: 0 0 28px;
    }
    .modal-dialog-centered{
        height:80%;
    }

    &.modal-preschool{
        z-index: 100000!important;
    }

    &.full-width{
        .modal-dialog-centered{
            max-width: 90%;
            .modal-content{
                height:100%;
            }
        }
    }
    &.add_exam2{
        .modal-body{
            overflow: initial;
        }
    }
    &.add_exam, &.statistics_exam{
        .modal-content{
            height: 100%;
            .modal-body{
                height: calc(100% - 75px);
                &::-webkit-scrollbar-track{
                    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                    background-color: #F5F5F5;
                }
                &::-webkit-scrollbar{
                    width: 5px;
                    height: 3px;
                    background-color: #F5F5F5;
                }
                &::-webkit-scrollbar-thumb{
                    background-color: #3eb6ad;
                }
                form{
                    .school-ligne-classes{
                        .error_form{
                            display: inline-block;
                        }
                        .RadioLegend {
                            width: 30%;
                            margin: 0 0 15px;
                            float: left;
                            display: flex;
                            >span{
                                padding: 0;
                                margin-right: 5px;
                            }
                            legend{
                                margin-bottom: 0;
                                line-height: 24px;
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 768px) {
            max-width: calc(var(--vw, 1vw) * 100);
            margin: 0;
            >div{
                max-width: calc(var(--vw, 1vw) * 100);
                height: calc(var(--vh, 1vh) * 100);
                margin: 0;
                .modal-content{
                    border-radius: 0;
                }
            }
        }
    }
    &.comment_content{
        z-index: 100000000000;
        > div{
            margin: auto;
            height: 50%;
            top: 25%;
            .modal-content{
                height: 100%;
            }
        }
    }
    &.edit_course_modal{
        .modal-dialog {
            max-width: 700px;
            .modal-body{
                overflow-x: visible;
            }
        }
        .all_classes{
            width: 98%;
        }
    }
    &.edit_purchase_modal{
        .modal-dialog {
            max-width: 700px;
            .modal-body{
                overflow-x: visible;
            }
        }
        .all_classes{
            width: 98%;
        }
    }
    &.edit_book_modal{
        .modal-dialog{
            max-width: 800px;
            .modal-content{
                height: max-content;
                max-height: 100%;
                padding: 10px 2px;
                .modal-body{
                    max-height: calc(100% - 63px);
                    height: max-content;
                    &::-webkit-scrollbar-track{
                        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                        background-color: #F5F5F5;
                    }
                    &::-webkit-scrollbar{
                        width: 5px;
                        height: 3px;
                        background-color: #F5F5F5;
                    }
                    &::-webkit-scrollbar-thumb{
                        background-color: #3eb6ad;
                    }
                }
            }
        }
    }
    &.books_modal_list{
        z-index: 100000;
    }
    &.event_users_list{
        width: 1000px;
        max-width: 1000px !important;
        left: 50%;
        transform: translateX(-50%);
        >div{
            max-width: 100%;
            .user-search-container{
                display: flex;
                width: 100%;
                height: 34px;
                position: relative;
                margin-bottom: 10px;
                border-radius: 17px;
                border: 1px solid rgb(203, 210, 218);
                font-size: 12px;
                padding: 0px 15px;
                background-color: rgb(255, 255, 255);
                box-shadow: none;
                -webkit-box-align: center;
                align-items: center;
                input{
                    width: max-content;
                    height: 30px;
                    flex: 1 1 0%;
                    border-radius: 17px;
                    border: transparent;
                    font-size: 12px;
                    padding-left: 0px;
                    margin-left: 5px;
                    background: transparent;
                }
            }
            .row{
                .col{
                    z-index: 111;
                    label{
                        margin: 0px;
                        font-weight: bold;
                        display: inline-block;
                    }
                    >div{
                        width: 100%;
                        z-index: 111;
                    }
                    .rs__menu-list{
                        z-index: 111;
                        &::-webkit-scrollbar-track{
                          -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                          background-color: #F5F5F5;
                          border-radius: 5px;
                        }
                        &::-webkit-scrollbar{
                          width: 10px;
                          /*height: 3px;*/
                          background-color: #F5F5F5;
                          border-radius: 5px;
                        }
                        &::-webkit-scrollbar-thumb{
                          background-color: #3eb6ad;
                          border-radius: 5px;
                        }
                        .rs__option{
                          border-bottom: 1px dashed #ccc;
                          cursor: pointer;
                        }
                        .rs__group{
                          .rs__group-heading{
                            padding: 8px 12px;
                            &:hover{
                              background-color: #86e6df4a;
                              cursor: pointer;
                            }
                          }
                          .rs__option{
                            padding-left: 25px;
                            cursor: pointer;
                          } 
                        }
                    }
                }
            }
        }
    }
}
.custom_modal.replacement_professors{
    .modal-dialog{
        max-width: 1000px !important;
        width: 100% !important;
        overflow: hidden;
        .modal-content{
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
        }
        .modal-header{
            display: flex;
            flex-wrap: wrap;
            .title{
                width: 100%;
                text-align: center;
                margin-bottom: 15px;
            }
            .header{
                width: 100%;
                .order_type{
                    .btn-default{
                        color: inherit;
                        background: #fff;
                        border: 0px solid #d1dbe2;
                        i{
                            margin-left: 5px;
                        }
                    }
                }
                .dropdown-menu{
                    padding: 8px;
                    li{
                        font-size: 12px;
                        border-bottom: 1px solid #d8d8d8;
                        margin-bottom: 2px;
                        cursor: pointer;
                    }
                }
            }
        }
        .modal-body{
            height: calc(100% - 120px);
            background: white;
            border-radius: 15px;
            margin-bottom: 15px;
        }
        @media (max-width: 1000px){
            margin: 0;
            border-radius: 0;
            .modal-content{
                border-radius: 0;
                .btn-close-modal{
                    right: 10px;
                }
            }
        }
        .list_professors_replacement{
            min-height: 200px;
            display: flex;
            flex-wrap: wrap;
            height: calc(100% - 55px);
            overflow-y: auto;

            &::-webkit-scrollbar-track{
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                background-color: #F5F5F5;
            }
            &::-webkit-scrollbar{
                width: 5px;
                height: 3px;
                background-color: #F5F5F5;
            }
            &::-webkit-scrollbar-thumb{
                background-color: #3eb6ad;
            }
        }
        .actions_modal{
            margin-top: 15px;
        }
    }
    @media (max-width: 1000px){
        width: 93%;
    }
}
.custom_modal.replacement_classrooms{
    .modal-dialog{
        max-width: 1000px !important;
        width: 100% !important;
        overflow: hidden;
        .modal-content{
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
        }
        .modal-header{
            display: flex;
            flex-wrap: wrap;
            .title{
                width: 100%;
                text-align: center;
                margin-bottom: 15px;
            }
            .header{
                width: 100%;
                .order_type{
                    .btn-default{
                        color: inherit;
                        background: #fff;
                        border: 0px solid #d1dbe2;
                        i{
                            margin-left: 5px;
                        }
                    }
                }
                .dropdown-menu{
                    padding: 8px;
                    li{
                        font-size: 12px;
                        border-bottom: 1px solid #d8d8d8;
                        margin-bottom: 2px;
                        cursor: pointer;
                    }
                }
            }
        }
        .modal-body{
            height: calc(100% - 120px);
            background: white;
            border-radius: 15px;
            margin-bottom: 15px;
        }
        @media (max-width: 1000px){
            margin: 0;
            border-radius: 0;
            .modal-content{
                border-radius: 0;
                .btn-close-modal{
                    right: 10px;
                }
            }
        }
        .list_classrooms_replacement{
            min-height: 200px;
            display: flex;
            flex-wrap: wrap;
            height: calc(100% - 55px);
            overflow-y: auto;

            &::-webkit-scrollbar-track{
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                background-color: #F5F5F5;
            }
            &::-webkit-scrollbar{
                width: 5px;
                height: 3px;
                background-color: #F5F5F5;
            }
            &::-webkit-scrollbar-thumb{
                background-color: #3eb6ad;
            }
        }
        .actions_modal{
            margin-top: 15px;
        }
    }
    @media (max-width: 1000px){
        width: 93%;
    }
}

.fixed_modal{
    .modal-dialog{
        height: 90%;
        .modal-content{
            height: 100%;
            .modal-body{
                overflow: auto;
                display:flex;
                flex-wrap:wrap
            }
        }
    }
}

.modal-body{
    padding: 2rem;
}

.gray_div{
    border-radius: 8px;
    background-color: #fcfcfd;
    border: solid 0.6px #d1dbe2;
    padding: 1.5em;
}

@mixin fullscreen-modal-mixin() {
    padding: 0px!important;
    overflow-y: hidden!important;
    .modal-dialog{
        max-width:100vw;
        height:100%;
        margin:0px;
        .modal-content{
            width:100vw;
            height:100%;
            border-radius:0px;
        }
    }
}
.arabic_keyboard_modal{
    width:fit-content!important;
    height: fit-content!important;
    .modal-dialog{
        margin: 0px;
        .modal-content{
            background:transparent;
            border:none;
        }
        .modal-body{
            padding:0px
        }
    }
}

.fullscreen_modal{
    @include fullscreen-modal-mixin();
    &.zzz_index{
        z-index: 99999;
    }
}

@media (max-width: $tablet) {
    .fullscreen_modal_xs{
        @include fullscreen-modal-mixin()
    }
}
.providers_payments{
    .modal-content{
        height: 100%;
    }
}
.internal_calls_visits{
    .modal-content{
        height: 100%;
        div.error{
            color: red;
        }
    }
}
.test_invitation{
    .modal-content{
        height: 100%;
    }
}

.bootstrap-select{
    &.dropup .dropdown-toggle:after, .dropdown-toggle:after{
        font: normal normal normal 14px/1 FontAwesome;
        border-radius: 50%;
        padding: 2px;
        background-color: #ccc;
        color: #fff;
        width: 18px;
        height: 18px;
        line-height: 15px;
        text-align: center;
        font-size: 18px;
        border: none !important;
        position: initial !important;
        margin: 0px !important;
        vertical-align: middle;
        content: '\f107';
    }
} 

.nav-exercises{
    flex-wrap: nowrap;
    display: flex;
    overflow: auto;
    &::-webkit-scrollbar-track{
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
    }
    &::-webkit-scrollbar{
        width: 5px;
        height: 3px;
        background-color: #F5F5F5;
    }
    &::-webkit-scrollbar-thumb{
        background-color: #3eb6ad;
    }
}

.nav-exercises .btn{
    position:relative;
    top: 100%;
    transform: translateY(-100%);
    margin:0px 2px;
    height: 30px;
    width: 30px;
    min-width: 30px;
    padding: 0px;
    &.active-exercise, &:hover{
        @extend .btn-green;
    }
}

.dragandrophandler{
    background-image: none;
    border: 2px dotted #b1c1cf;
    width: 100%;
    color: #92AAB0;
    text-align: left;
    vertical-align: middle;
    text-align: center;
    padding: 15px 0;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    strong{
        color:#303c54
    }
    input{
        position: absolute;
        opacity: 0;
        height: 100%;
        width: 100%;
        top:0px;
        left:0px;
    }
}

.modal_create_groupe .modal-dialog{
    max-width: 620px;
    .modal-header{
        padding-bottom: 20px;
    }
    .date_start_end{
        display: flex;
        .date__challenge{
          width: 49%;
          &:first-of-type{
            margin-right: 2%;
          }
        }
    }
    @media (max-width: 1199px) {
        margin: 0 auto;
        .modal-content{
            border-radius: 0;
        }
    }
    @media (max-width: 767px) {
        margin: 0 auto;
        height: calc(100%);
    }
}

@keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
}


body > .chapter_sortable{
    display: block;
    >div{
        background: transparent;
        border: 0;
        box-shadow: none;
    }
    min-height: 190px;
    margin-top:10px;
    /* height: 156px; */
    object-fit: contain;
    border-radius: 10px;
    border: solid 1px #d1dbe2;
    background-color: #FFF;
    border-bottom: 6px solid #f2985a;
    padding: 6px 20px 0;
    position: relative;
    .img{
        width: 96px;
        height: 96px;
        display: block;
        text-align: center;
        line-height: 96px;
        margin: auto;
        img{
        width: 96px;
        height: 96px;
        }
    }
    h4{
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: normal;
        text-align: center;
        color: #1b2437;
        margin: 16px 0 0;
    }
    z-index: 11111111;
}

body > .top-comp{
    display: block;
    margin-top: 6px;
    padding-left: 25px;
    object-fit: contain;
    margin-left: 20px;
    position: relative;
    i{
        cursor: pointer;
        top: 50%;
        position: absolute;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        color: #adadad;
    }
    h4{
        float: left;
        opacity: 0.5;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        -webkit-letter-spacing: normal;
        -moz-letter-spacing: normal;
        -ms-letter-spacing: normal;
        letter-spacing: normal;
        color: #1b2437;
        margin: 0;
        margin-left: 20px;
    }
}


body > tr{
    &.competency{
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        td{
            line-height: 30px;
            border-right: 1px solid #d1dbe2;
            border-bottom: 1px solid #d1dbe2;
            padding: 1px 4px;
            color: #adadad;
            background-color: #FFF;
            font-size: 0.8rem;
            height: 47px;
            min-width: 50px;
            position: relative;
            &.m_w_20_p{
                min-width: 300px;
                width: 20%;
            }
            &.b_t{
                border-top: 1px solid #d1dbe2 !important;
            }
            &.b_l{
                border-left: 1px solid #d1dbe2 !important;
            }
            &.b_b{
                border-bottom: 1px solid #d1dbe2 !important;
            }
            &.b_r{
                border-right: 1px solid #d1dbe2 !important;
            }
            .competency_name_container{
                display: inline-block;
                width: calc(100% - 13px);
            }
            > div{
                padding: 6px;
                background: transparent;
            }
            &:not(:first-child){
                text-align: center;
                color: #333;
                font-weight: bold;
                width: inherit;
            }
            &.d-md-table-cell {
                display: table-cell !important;
            }
            .custom-select{
                position: relative;
                color: rgba(0, 0, 0, 0.87);
                cursor: text;
                display: inline-flex;
                font-size: 1rem;
                font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                line-height: 1.1875em;
                align-items: center;
                transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                width: 100%;
                height: calc(1.5em + 0.75rem + 2px);
                font-weight: 400;
                vertical-align: middle;
                border: 1px solid #ced4da;
                border-radius: 0.25rem;
                appearance: none;
            }
        }
    }
}
body > li{
    display: block;
    >div{
        width: 100%;
        visibility: visible !important;
        opacity: 1 !important;
        display: flex;
        justify-content: space-between;
        border: 1px solid rgb(176,190,204);
        padding: 5px;
        margin-bottom: 15px;
        border-radius: 10px;
        box-shadow: 5px 5px 15px -2px;
        line-height: 27px;
        .exercice_name{
            display: flex;
            .exercice_logo{
                margin-right: 10px;
                img{
                    width: 30px;
                    height: 30px;
                }
            }
        }
        .actions{
            display: none;
            padding: 0;
            width: 100px;
            display: flex;
            justify-content: space-between;
            i{
                padding: 8px;
                cursor: pointer;
            }
        }
    }
    &.field_drag{
        z-index: 99999999;
        position: relative;
        width: max-content;
        display: flex;
        min-width: max-content;
        padding: 5px 10px;
        border: 2px solid #d1dbe2;
        background: white;
        font-weight: bold;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        height: 39px;
        position: relative;
        .field{
            // height: 35px;
            border: 0px solid rgb(226, 227, 228);
            box-shadow: none;
            text-align: left;
            width: max-content;
            padding: 0;
            margin: 0;
        }
        i.input_checkbox{
            width: 24px;
            height: 24px;
            font-family: fontawesome,Montserrat, sans-serif;
            border: 1px solid;
            border-radius: 50%;
            padding: 3px;
            padding-right: 5px;
            font-size: 15px;
            color: #b7b6b6;
            position: relative;
            &.checked{
                color: white;
                background: #58a182;
            }
            &::before{
                height: 20px;
                width: 20px;
                margin-top: -5px;
                position: absolute;
                left: 2px;
            }
        }
        .fa-arrows{
            visibility: hidden;
        }
    }
    &.item_tree{
        z-index: 99999999;
        position: relative;
        .item_header{
            background-color: #f4f9ff;
            padding: 0.5vw 0.8vw !important;
            position: relative;
            cursor: pointer;
            text-align: left;
            font-size: 0.75vw;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            img{
                margin-right: 3%;
                width: 2.19vw;
                height: 2.19vw;
            }
            .name-container{
                margin-right: 80px;
                display: inline-flex;
                flex: 1;
                span{
                    white-space: unset !important;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden !important;
                    text-overflow: ellipsis !important;
                    display: -webkit-box;
                    width: 100%;
                    top: 22%;
                    margin: auto;
                }
            }
            i{
                top: 50%;
                right: 10px;
                position: absolute;
                transform: translateY(-50%);
                cursor: pointer;
                color: #fff;
                background-color: #d1dbe2;
                padding: 4px;
                border-radius: 50%;
                width: 22px;
                height: 22px;
                text-align: center;
                font-size: 0.875vw;
                left: unset;
            }
        }
        &.chapter_item{
            &:not(.open){
                .child_items, .actions{
                    display: none;
                }
            }
            .actions{
                border-top: 1px solid #dee4ec;
                margin: -4px;
                .add_chapter_btn{
                    border: none;
                    -webkit-box-pack: center;
                    -webkit-justify-content: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    padding: 6px;
                    align-items: center;
                    cursor: pointer;
                    font-size: 2.19vw;
                    display: flex;
                    border-radius: 10px;
                    flex-basis: 100%;
                    .btn-circle.btn-yellow{
                        font-size: 2.19vw;
                        margin: 2px 5px;
                        min-width: 2.19vw;
                        min-height: 2.19vw;
                        display: -webkit-box;
                        display: -webkit-flex;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-align-items: center;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        -webkit-box-pack: center;
                        -webkit-justify-content: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        box-shadow: 0 18px 36px 0 rgba(239, 194, 91, 0.5);
                        background-image: linear-gradient(225deg, #fdd271, #d5a433);
                        color: #fff;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        border: 0px !important;
                        padding: 0px;
                        font-weight: 400;
                        text-align: center;
                        vertical-align: middle;
                        user-select: none;
                        background-color: transparent;
                    }
                    .title{
                        font-size: 0.875vw;
                        font-weight: bold;
                    }
                }
            }
            .item_header{
               i{
                    &.remove_xblock{
                        right: 70px;
                    }
                    &.edit_xblock{
                        right: 40px;
                    }
                    &.clone_xblock{
                        right: 100px;
                    }
                    &.sort_item{
                        right: 130px;
                    }
                }
            }
        }
        &.slide_item{
            .item_header{
               i{
                    &.remove_xblock{
                        right: 72px;
                    }
                    &.edit_xblock{
                        right: 42px;
                    }
                    &.clone_xblock{
                        right: 102px;
                    }
                    &.sort_item{
                        right: 132px;
                    }
                }
            }
        }
    }
    &.subject_details_sort{
        >div{
            background: transparent;
            border: 0;
            box-shadow: none;
        }
    }
    &.payment_type_sort{
        >div{
            background: transparent;
            border: 0;
            box-shadow: none;
        }
    }
    .stepTbl{
        width: 100%;
        position: relative;
        border-bottom: solid 0.6px #d1dbe2;
        padding-top: 5px;
        opacity: 1 !important;
        height: 37px;
        background-color: #ffffff;
        .name{
            width: calc(100% - 100px);
            text-align: left;
            margin-top: 5px;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            -webkit-letter-spacing: normal;
            -moz-letter-spacing: normal;
            -ms-letter-spacing: normal;
            letter-spacing: normal;
            color: #1b2437;
            display: inline-block;
            vertical-align: middle;
            padding: 0 3px;
            height: 100%;
        }
        .actions{
            display: inline-block;
            width: 100px;
            span{
                margin-left: 0px;
                i{
                    color: #a1a9b3;
                    font-size: 18px;
                    cursor: pointer;
                }
            }
        }
    }
}
body > .survey_item_choice{
    display: flex;
    position: relative;
    z-index: 99999999;
    padding-left: 15px;
    .fa-arrows{
      display: block;
      position: absolute;
      left: 0;
      cursor: pointer;
      top: 50%;
      transform: translate(-15%, -50%);
      height: 100%;
      line-height: 35px;
      font-size: 14px;
      color: #bdbdbd;
    }
    .survey_item_choice_label{
      margin-bottom: 7px;
      padding: 0 !important;
      width: 100%;
      input{
        font-size: 20px;
        height: inherit;
        float: left;
        width: 100%;
        border-radius: 2.6px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        font-weight: 400;
        color: #1b2437 !important;
      }
      .error{
          display: none;
      }
    }
    .survey_questions_type_icon{
      width: 20px;
      height: 100%;
      margin-right: 5px;
      i{
        font-size: 20px;
        line-height: 35px;
        color: #bdbdbd;
      }
    }
    .remove_survey_item_choice{
      width: 20px;
      height: 100%;
      margin-left: 5px;
      i{
        font-size: 20px;
        line-height: 35px;
        color: #bdbdbd;
        cursor: pointer;
      }
    }
    
  }
body > .survey_part{
    border-radius: 10px;
    border-left: 5px solid transparent;
    position: relative;
    background: white;
    padding: 15px 2px;
    padding-top: 20px;
    z-index: 99999999;
    .error{
        display: none;
    }
    .fa-arrows.item{
      display: block;
      position: absolute;
      left: 50%;
      cursor: pointer;
      top: 0%;
      transform: translate(-50%, -15%);
      line-height: 35px;
      font-size: 14px;
      color: #bdbdbd;
    }
    .survey_actions_list{
      display: none;
      position: absolute;
      right: 10px;
      top: 20px;
      width: 40px;
      padding: 5px;
      border-radius: 5px;
      background: #f0ebf8;
      >div{
        width: 30px;
        height: 30px;
        text-align: center;
        margin-bottom: 5px;
        span{
          display: block;
          border-radius: 50%;
          border: 2px solid gray;
          color: gray;
          padding: 3px;
          cursor: pointer;
          font-weight: bold;
          font-size: 21px;
          line-height: 20px;
        }
      }
    }
    .survey_item_footer{
      display: none;
      justify-content: end;
      float: left;
      padding: 10px 15px;
      width: 100%;
      >div{
        margin-left: 10px;
        i{
          cursor: pointer;
          color: gray;
        }
      }
    }
    &.focused{//}, &:focus-within, &:focus-visible{
      border-left: 5px solid #efc15b;
      padding-right: 50px;
      .survey_actions_list{
        display: block;
      }
      .survey_item_footer{
        display: flex;
      }
    }
    >.survey_item_name{
      margin-bottom: 7px;
      width: 100%;
      padding: 0 12px;
      input, textarea{
          float: left;
          width: 100%;
          height: 28px;
          border-radius: 2.6px;
          border: solid 0.6px #d1dbe2;
          background-color: rgba(245, 248, 249, 0.3);
          font-size: 14px;
          font-weight: 400;
          color: #1b2437 !important;
      }
      input{
        font-size: 20px;
        height: inherit;
        width: 100%;
        padding: 0 12px;
      }
    }
    .name_img{
      .survey_item_name{
        margin-bottom: 7px;
        width: 100%;
        padding: 0 12px;
        input, textarea{
            float: left;
            width: 100%;
            height: 28px;
            border-radius: 2.6px;
            border: solid 0.6px #d1dbe2;
            background-color: rgba(245, 248, 249, 0.3);
            font-size: 14px;
            font-weight: 400;
            color: #1b2437 !important;
        }
        input{
          font-size: 25px;
          font-weight: bold;
          height: inherit;
          width: 100%;
          padding: 0 12px;
        }
      }
      .survey_item_image_select{
        height: 100%;
        line-height: 40px;
        padding-right: 10px;
        >i{
            cursor: pointer;
            color: gray;
            height: 100%;
            font-size: 20px;
        }
      }
    }
    .survey_item_image{
        width: 100%;
        height: 300px;
        margin: 16px 0;
        display: flex;
        &.legend{
            margin-bottom: 50px;
        }
        >div{
            width: max-content;
            height: max-content;
            max-width: 100%;
            max-height: 100%;
            position: relative;
            >.action{
                display: none;
            }
            img{
                max-width: 100%;
                max-height: 300px;
                width: 100%;
            }
        }
        .legend_image{
            position: absolute;
            top: 102%;
            left: 0;
            width: max-content;
            min-width: 100%;
        }
    }
    .survey_item_description{
      margin-bottom: 7px;
      textarea{
        height: 40px;
        float: left;
        width: 100%;
        border-radius: 2.6px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        font-size: 14px;
        font-weight: 400;
        color: #1b2437 !important;
      }
    }
    &.question{
      .name_img{
        display: flex;
      }
    }
    &.paragraphe{
      .name_img{
        display: flex;
      }
    }
    .survey_item_choices{
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 12px;
    }
    .survey_item_choice{
      display: flex;
      position: relative;
      flex-wrap: wrap;
      >div{
        display: flex;
        width: 100%;
      }
      .fa-arrows {
        display: none;
        position: absolute;
        left: 0;
        cursor: pointer;
        top: 50%;
        -webkit-transform: translate(-15%, -50%);
        transform: translate(-15%, -50%);
        height: 100%;
        line-height: 35px;
        font-size: 14px;
        color: #bdbdbd;
      }
      .survey_item_choice_label{
        margin-bottom: 7px;
        padding: 0 !important;
        width: 100%;
        input{
            float: left;
            width: 100%;
            height: 28px;
            border-radius: 2.6px;
            border: solid 0.6px #d1dbe2;
            background-color: rgba(245, 248, 249, 0.3);
            font-size: 14px;
            font-weight: 400;
            color: #1b2437 !important;
          font-size: 20px;
          height: inherit;
        }
      }
      .survey_questions_type_icon{
        width: 20px;
        height: 100%;
        margin-right: 5px;
        i{
          font-size: 20px;
          line-height: 35px;
          color: #bdbdbd;
        }
      }
      .remove_survey_item_choice{
        width: 20px;
        height: 100%;
        margin-left: 5px;
        i{
          font-size: 20px;
          line-height: 35px;
          color: #bdbdbd;
          cursor: pointer;
        }
      }
      .survey_item_choice_image{
        width: 100%;
        height: 100px;
        margin: 16px 0;
        display: flex;
        >div{
          width: max-content;
          height: max-content;
          max-width: 100%;
          max-height: 100%;
          position: relative;
          img{
            max-width: 100%;
            max-height: 100px;
            width: 100%;
          }
          .remove_survey_item_choice_image{
            display: none;
          }
        }
      }
      &.add_options{
          flex-wrap: nowrap;
        .survey_item_choice_label{
          display: flex;
          justify-content: start;
          margin-bottom: 0;
          line-height: 30px;
          span{
            color: gray;
            &.add_option{
              color: #1a73e8;
              cursor: pointer;
              margin-right: 5px;
            }
            &.add_other{
              color: #1a73e8;
              cursor: pointer;
              margin-left: 5px;
            }
          }
        }
      }
    }
    &.focused{
      padding-left: 8px;
      .survey_item_choice {
        padding-left: 15px;
        margin-left: -15px;
      }
    }
  }

.body > .control_item_settings{
    
}
.w_600{
  width: 600px !important;
}
.w_500{
  width: 500px !important;
}
.m_t_80_im{
    margin-top: 80px !important;
}
.h_100{
    height: 100%;
    p.p_render{
        height: 100%;
        overflow-y: auto;
    }
    .tox-toolbar__primary{
        width: 100%;
        padding-right: 20px;
    }
}

.modal_activities{
    &.modal_activities_preschools{
        .modal-content{
            height: 100%;
        }
        // .modal-body{    
        //     overflow: hidden!important;
        // }
    }
    >div{
        max-width: 700px;
        border-radius: 15px;
        height: 90%;
        max-height: 90%;
        .modal-content{
            border-radius: 15px;
            height: max-content;
            max-height: 100%;
            padding-bottom: 15px;
            .btn-close{
                z-index: 111;
            }
            .loading{
                position: absolute;
                width: 100%;
                height: 100%;
                .loading_img{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: black;
                    z-index: 111;
                    opacity: 0.2;
                    border-radius: 10px;
                img{
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    max-width: 50%;
                    max-height: 50%;
                }
                }
            }
            >div{

                overflow: auto;

                &::-webkit-scrollbar-track{
                    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                    background-color: #F5F5F5;
                }
                &::-webkit-scrollbar{
                    width: 5px;
                    height: 3px;
                    background-color: #F5F5F5;
                }
                &::-webkit-scrollbar-thumb{
                    background-color: #3eb6ad;
                }
            }
            .modal-header{
                > div{
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    button{
                        margin-right: 0 !important;
                        background: #00b3a9;
                        background-image: none;
                        position: absolute;
                        top: -23px;
                        right: -23px;
                        border: 5px solid #fff !important;
                        width: 40px;
                        height: 40px;
                        text-align: center;
                        color: #fff;
                        border-radius: 50%;
                        font-size: 31px;
                        line-height: 28px;
                        box-shadow: none;
                        background-image: none;
                        font-weight: bold;
                        >span{
                            font-size: 35px !important;
                            font-weight: normal !important;
                            text-shadow: 0 1px 0 #fff !important;
                            line-height: 30px;
                            font-family: inherit;
                        }
                    }
                }
            }
            .modal-body{    
                height: max-content;
                max-height: calc(100% - 56px);
                overflow: auto;
                &::-webkit-scrollbar-track{
                    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                    background-color: #F5F5F5;
                }
                &::-webkit-scrollbar{
                    width: 5px;
                    height: 3px;
                    background-color: #F5F5F5;
                }
                &::-webkit-scrollbar-thumb{
                    background-color: #3eb6ad;
                }
                border-bottom-left-radius: 15px;
                border-bottom-right-radius: 15px;
                .params{
                    .line{
                        display: flex;
                        >div{
                            width: 100%;
                        }
                        .textErrors{
                            color: red;
                        }
                        .radioLevels{
                            display: flex;
                            flex-wrap: wrap;
                            .RadioLegend{
                                width: 33%;
                                display: flex;
                                >span{
                                    padding: 0;
                                    margin-right: 5px;
                                    height: max-content;
                                }
                                legend{
                                    margin-bottom: 0;
                                    padding-top: 3px;
                                }
                            }
                        }
                        &.image{
                            >div{
                                text-align: center;
                                img{
                                    max-width: 100px;
                                    max-height: 100px;
                                    margin: auto;
                                    border: 1px solid #b7b7b7;
                                    padding: 5px;
                                    border-radius: 5px;
                                }
                                span{
                                    width: max-content;
                                    margin: auto;
                                    background: #00b3a9;
                                    color: white;
                                    cursor: pointer;
                                    padding: 5px 10px;
                                    border-radius: 15px;

                                }
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                            }
                        }
                        .react-datepicker-wrapper{
                            margin-left: 0;
                        }
                        .auto_textarea{
                            textarea{
                                padding: 5px;
                                border-radius: 2.6px;
                                border: 0.6px solid #d1dbe2;
                                background-color: rgba(245,248,249,.3);
                                font-size: 14px;
                                font-weight: 400;
                                color: #1b2437!important;
                                margin-top: 3px;
                                width: 100%;
                            }
                        }
                    }
                    .templates{
                        .drag_div{
                            position: relative;
                            width: 100%;
                            margin: 20px 0px;
                            .template{
                                display: flex;
                                -webkit-box-pack: justify;
                                justify-content: space-between;
                                padding: 0px 5px;
                                .name{
                                    width: calc(100% - 15px);
                                    text-align: left;
                                    display: block;
                                }
                                .remove_template{
                                    color: red;
                                }
                            }
                        }
                    }
                    .video_url,.link_url{
                        display: flex;
                        flex-wrap: wrap;
                        label{
                            margin-right: 10px;
                            width: 80px;
                        }
                        input{
                            width: calc(100% - 90px);
                            margin-bottom: 10px;
                        }
                        button{
                            margin: auto;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
        .mb-36{
            margin-bottom: 36px !important;
        }
        .fileUpload{
            display: inline-block;
            min-width: 105px;
            height: 30px;
            object-fit: contain;
            border-radius: 49.5px;
            text-align: center;
            font-family: Montserrat;
            line-height: 30px;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
            background-image: linear-gradient(54deg, #3eb6ad, #72dcd6);
            color: #fff;
            border: none;
            float: left;
            margin-bottom: 0px;
            input{
                max-width: 95px;
                visibility: hidden;
                position: absolute;
                width: auto;
                z-index: 999;
                padding-left: 10px;
                float: left;
                height: 26.5px;
                border-radius: 2.6px;
                border: solid 0.6px #d1dbe2;
                background-color: rgba(245, 248, 249, 0.3);
                font-size: 14px;
                font-weight: 500;
            }
            i{
                margin-right: 3px;
                font-size: 15px;
            }
        }
    }
    &.modal_bibliotheque{
        .modal-content{
            padding-bottom: 0px;
            border: 0;
        }
    }
}
.modal_extra_scolaire_activities{
    >div{
        min-width: 600px;
        width: 70%;
        max-width: 100%;
        border-radius: 35px;
        height: max-content;
        min-height: 500px;
        max-height: 90%;
        .modal-content{
            border-radius: 30px;
            height: 100%;
            max-height: 100%;
            padding-bottom: 15px;
            .btn-close{
                z-index: 111;
            }
            .loading{
                position: absolute;
                width: 100%;
                height: 100%;
                .loading_img{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: black;
                    z-index: 111;
                    opacity: 0.2;
                    border-radius: 10px;
                img{
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    max-width: 50%;
                    max-height: 50%;
                }
                }
            }
            .bootstrap-select{
                >button.bs-placeholder{
                    >span.filter-option{
                        color: black;
                    }
                }
            }
            >div{

                overflow: auto;

                &::-webkit-scrollbar-track{
                    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                    background-color: #F5F5F5;
                }
                &::-webkit-scrollbar{
                    width: 5px;
                    height: 3px;
                    background-color: #F5F5F5;
                }
                &::-webkit-scrollbar-thumb{
                    background-color: #3eb6ad;
                }
            }
            .modal-header{
                border-top-right-radius: 29px;
                border-top-left-radius: 29px;
                > div{
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    button{
                        margin-right: 0 !important;
                        background: #00b3a9;
                        background-image: none;
                        position: absolute;
                        top: -15px;
                        right: -15px;
                        border: 5px solid #fff !important;
                        width: 40px;
                        height: 40px;
                        text-align: center;
                        color: #fff;
                        border-radius: 50%;
                        font-size: 31px;
                        line-height: 28px;
                        box-shadow: none;
                        background-image: none;
                        font-weight: bold;
                        >span{
                            font-size: 35px !important;
                            font-weight: normal !important;
                            text-shadow: 0 1px 0 #fff !important;
                            line-height: 30px;
                            font-family: inherit;
                        }
                    }
                }
            }
            .modal-body{    
                height: 100%;
                max-height: calc(100% - 56px);
                overflow: auto;
                padding: 30px;
                &::-webkit-scrollbar-track{
                    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                    background-color: #F5F5F5;
                }
                &::-webkit-scrollbar{
                    width: 5px;
                    height: 3px;
                    background-color: #F5F5F5;
                }
                &::-webkit-scrollbar-thumb{
                    background-color: #3eb6ad;
                }
                border-bottom-left-radius: 15px;
                border-bottom-right-radius: 15px;
                .rows{
                    height: 100%;
                    .col-md-4{
                        padding: 20px;
                    }
                    .line{
                        display: flex;
                        >div{
                            width: 100%;
                            .checkbox-form{
                                height: 42px;
                                .p-info{
                                    >label{
                                        margin: 0;
                                        &:before{
                                            top: 50%!important;
                                            transform: translateY(-50%)!important
                                        }
                                        &:after{
                                            top: 50%!important;
                                            transform: translateY(-50%) scale(0.6)!important
                                        }
                                        >label{
                                            text-indent: 0;
                                            margin-top: 12px;
                                            &:before, &::after{
                                                content: "";
                                                display: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .textErrors{
                            color: red;
                        }
                        .dropdown-menu{
                            max-height: 300px !important;
                        }
                        .radioLevels{
                            display: flex;
                            flex-wrap: wrap;
                            .RadioLegend{
                                width: 33%;
                                display: flex;
                                >span{
                                    padding: 0;
                                    margin-right: 5px;
                                    height: max-content;
                                }
                                legend{
                                    margin-bottom: 0;
                                    padding-top: 3px;
                                }
                            }
                        }
                        &.image{
                            >div{
                                text-align: center;
                                img{
                                    max-width: 250px;
                                    max-height: 250px;
                                    width: 250px;
                                    height: 250px;
                                    margin: auto;
                                    border: 1px solid #b7b7b7;
                                    padding: 5px;
                                    border-radius: 50%;
                                    margin: auto;
                                }
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                            }
                        }
                        &.browse_btn{
                            margin-top: 25px;
                            >div{
                                text-align: center;
                                span{
                                    width: max-content;
                                    margin: auto;
                                    background: #00b3a9;
                                    color: white;
                                    cursor: pointer;
                                    padding: 5px 10px;
                                    border-radius: 15px;

                                }
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                            }
                        }
                        &:not(.image):not(.browse_btn){
                            height: 90px;
                            >div{
                                width: 50%;
                                margin: 0 10px;
                                label{
                                    font-size: 20px;
                                }
                                input{
                                    height: 42px;
                                    font-size: 20px;
                                }
                                .selectInput{
                                    height: 42px;
                                    font-size: 20px;
                                }
                                .react-datepicker-wrapper{
                                    height: 42px;
                                    font-size: 20px;
                                    .react-datepicker__input-container{
                                        height: 42px;
                                        font-size: 20px;
                                        &:before{
                                            line-height: 42px;
                                        }
                                        input{
                                            height: 42px;
                                            font-size: 20px;
                                        }
                                    }
                                }
                                .bootstrap-select{
                                    width: 100%;
                                    height: 42px;
                                    >button{
                                        &:after{

                                        }
                                    }
                                }
                            }
                        }
                        .react-datepicker-wrapper{
                            margin-left: 0;
                        }
                        .auto_textarea{
                            textarea{
                                padding: 5px;
                                border-radius: 2.6px;
                                border: 0.6px solid #d1dbe2;
                                background-color: rgba(245,248,249,.3);
                                font-size: 14px;
                                font-weight: 400;
                                color: #1b2437!important;
                                margin-top: 3px;
                                width: 100%;
                            }
                        }
                    }
                    .templates{
                        .drag_div{
                            position: relative;
                            width: 100%;
                            margin: 20px 0px;
                            .template{
                                display: flex;
                                -webkit-box-pack: justify;
                                justify-content: space-between;
                                padding: 0px 5px;
                                .name{
                                    width: calc(100% - 15px);
                                    text-align: left;
                                    display: block;
                                }
                                .remove_template{
                                    color: red;
                                }
                            }
                        }
                    }
                    .video_url,.link_url{
                        display: flex;
                        flex-wrap: wrap;
                        label{
                            margin-right: 10px;
                            width: 80px;
                        }
                        input{
                            width: calc(100% - 90px);
                            margin-bottom: 10px;
                        }
                        button{
                            margin: auto;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
        .dropdown-menu.inner{
            padding-bottom: 15px !important;
        }
    }
    &.modal_bibliotheque{
        .modal-content{
            padding-bottom: 0px;
            border: 0;
        }
    }
}
.modal_students_extra_scolaire_activities{
    >div{
        min-width: 600px;
        width: 70%;
        max-width: 100%;
        border-radius: 35px;
        height: 90%;
        min-height: 500px;
        max-height: 90%;
        .modal-content{
            border-radius: 30px;
            height: 100%;
            max-height: 100%;
            padding-bottom: 15px;
            .btn-close{
                z-index: 111;
            }
            .loading{
                position: absolute;
                width: 100%;
                height: 100%;
                .loading_img{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: black;
                    z-index: 111;
                    opacity: 0.2;
                    border-radius: 10px;
                img{
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    max-width: 50%;
                    max-height: 50%;
                }
                }
            }
            >div{

                overflow: auto;

                &::-webkit-scrollbar-track{
                    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                    background-color: #F5F5F5;
                }
                &::-webkit-scrollbar{
                    width: 5px;
                    height: 3px;
                    background-color: #F5F5F5;
                }
                &::-webkit-scrollbar-thumb{
                    background-color: #3eb6ad;
                }
            }
            .modal-header{
                border-top-right-radius: 29px;
                border-top-left-radius: 29px;
                > div{
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    button{
                        margin-right: 0 !important;
                        background: #00b3a9;
                        background-image: none;
                        position: absolute;
                        top: -15px;
                        right: -15px;
                        border: 5px solid #fff !important;
                        width: 40px;
                        height: 40px;
                        text-align: center;
                        color: #fff;
                        border-radius: 50%;
                        font-size: 31px;
                        line-height: 28px;
                        box-shadow: none;
                        background-image: none;
                        font-weight: bold;
                        >span{
                            font-size: 35px !important;
                            font-weight: normal !important;
                            text-shadow: 0 1px 0 #fff !important;
                            line-height: 30px;
                            font-family: inherit;
                        }
                    }
                }
            }
            .modal-body{    
                height: 100%;
                max-height: calc(100% - 56px);
                overflow: auto;
                padding: 30px;
                padding-bottom: 0;
                &::-webkit-scrollbar-track{
                    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                    background-color: #F5F5F5;
                }
                &::-webkit-scrollbar{
                    width: 5px;
                    height: 3px;
                    background-color: #F5F5F5;
                }
                &::-webkit-scrollbar-thumb{
                    background-color: #3eb6ad;
                }
                border-bottom-left-radius: 15px;
                border-bottom-right-radius: 15px;
                .filters.row{
                    padding: 0 20px;
                    label{
                        margin-right: 5px;
                    }
                    >div{
                        margin-right: 15px;
                        margin-bottom: 10px;
                        button{
                            &:after{
                                position: absolute !important;
                                top: 8px;
                            }
                        }
                        >select{
                            width: 220px;
                            position: relative;
                            display: inline-flex;
                            vertical-align: middle;
                            color: #3a3a3a;
                            height: 38px;
                            padding-right: 25px;
                            z-index: 1;
                            background: #fff;
                            border: 1px solid #d1dbe2;
                        }
                    }
                }
                .rows{
                    height: calc(100% - 60px);
                    .col-md-4{
                        padding: 20px;
                    }
                    .students_list{
                        height: calc(100% - 60px);
                        width: 100%;
                        overflow-y: auto;
                        padding-left: 20px;
                        &::-webkit-scrollbar-track{
                            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                            background-color: #F5F5F5;
                        }
                        &::-webkit-scrollbar{
                            width: 5px;
                            height: 3px;
                            background-color: #F5F5F5;
                        }
                        &::-webkit-scrollbar-thumb{
                            background-color: #3eb6ad;
                        }
                        .students_list_table{
                            width: 100%;
                            tr{
                                &.is_child_of_staff{
                                    background-color: #ffe599;
                                }
                                &.header{
                                    th{
                                        background-color: #e7e6e6;
                                        border: 1px solid #7f7f7f;
                                        text-align: center;
                                        font-size: 18px;
                                        padding: 5px;
                                        &.order{
                                            border: 0 solid #7f7f7f;
                                            background-color: transparent;
                                        }
                                        &.student_name{

                                        }
                                        &.student_classe{

                                        }
                                        &.amount{

                                        }
                                        &.payed{

                                        }
                                    }
                                    &.classes{
                                        background-color: #e7e6e6;
                                        th{
                                            background-color: transparent;
                                        }
                                    }
                                }
                                &.total{
                                    background-color: #6afae2;
                                    font-weight: bold;
                                    font-size: 18px;
                                    &.classes{
                                        background-color: #07cb98;
                                    }
                                    td{
                                        height: 50px;
                                    }
                                }
                                td{
                                    border: 1px solid #7f7f7f;
                                    text-align: center;
                                    height: 40px;
                                    padding: 5px;
                                    &.order{
                                        
                                    }
                                    &.student_name{

                                    }
                                    &.student_classe{

                                    }
                                    &.amount{

                                    }
                                    &.payed{
                                        .react-switch-bg{
                                            height: 17px !important;
                                            width: 46px !important;
                                            >div{
                                                height: 17px !important;
                                                width: 28px !important;
                                            }
                                        }
                                        .react-switch-handle{
                                            height: 15px !important;
                                            width: 15px !important;
                                        }
                                        >span{
                                            display: block;
                                        }

                                    }
                                }
                            }
                        }
                    }
                    .line{
                        display: flex;
                        >div{
                            width: 100%;
                        }
                        .textErrors{
                            color: red;
                        }
                        .radioLevels{
                            display: flex;
                            flex-wrap: wrap;
                            .RadioLegend{
                                width: 33%;
                                display: flex;
                                >span{
                                    padding: 0;
                                    margin-right: 5px;
                                    height: max-content;
                                }
                                legend{
                                    margin-bottom: 0;
                                    padding-top: 3px;
                                }
                            }
                        }
                        &.image{
                            >div{
                                text-align: center;
                                img{
                                    max-width: 250px;
                                    max-height: 250px;
                                    width: 250px;
                                    height: 250px;
                                    margin: auto;
                                    border: 1px solid #b7b7b7;
                                    padding: 5px;
                                    border-radius: 50%;
                                    margin: auto;
                                }
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                            }
                        }
                        &.browse_btn{
                            margin-top: 25px;
                            >div{
                                text-align: center;
                                span{
                                    width: max-content;
                                    margin: auto;
                                    background: #00b3a9;
                                    color: white;
                                    cursor: pointer;
                                    padding: 5px 10px;
                                    border-radius: 15px;

                                }
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                            }
                        }
                        &:not(.image):not(.browse_btn){
                            height: 90px;
                            >div{
                                width: 50%;
                                margin: 0 10px;
                                label{
                                    font-size: 20px;
                                }
                                input{
                                    height: 42px;
                                    font-size: 20px;
                                }
                                .selectInput{
                                    height: 42px;
                                    font-size: 20px;
                                }
                                .react-datepicker-wrapper{
                                    height: 42px;
                                    font-size: 20px;
                                    .react-datepicker__input-container{
                                        height: 42px;
                                        font-size: 20px;
                                        &:before{
                                            line-height: 42px;
                                        }
                                        input{
                                            height: 42px;
                                            font-size: 20px;
                                        }
                                    }
                                }
                                .bootstrap-select{
                                    width: 100%;
                                    height: 42px;
                                    >button{
                                        &:after{

                                        }
                                    }
                                }
                            }
                        }
                        .react-datepicker-wrapper{
                            margin-left: 0;
                        }
                        .auto_textarea{
                            textarea{
                                padding: 5px;
                                border-radius: 2.6px;
                                border: 0.6px solid #d1dbe2;
                                background-color: rgba(245,248,249,.3);
                                font-size: 14px;
                                font-weight: 400;
                                color: #1b2437!important;
                                margin-top: 3px;
                                width: 100%;
                            }
                        }
                    }
                    .templates{
                        .drag_div{
                            position: relative;
                            width: 100%;
                            margin: 20px 0px;
                            .template{
                                display: flex;
                                -webkit-box-pack: justify;
                                justify-content: space-between;
                                padding: 0px 5px;
                                .name{
                                    width: calc(100% - 15px);
                                    text-align: left;
                                    display: block;
                                }
                                .remove_template{
                                    color: red;
                                }
                            }
                        }
                    }
                    .video_url,.link_url{
                        display: flex;
                        flex-wrap: wrap;
                        label{
                            margin-right: 10px;
                            width: 80px;
                        }
                        input{
                            width: calc(100% - 90px);
                            margin-bottom: 10px;
                        }
                        button{
                            margin: auto;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
    &.modal_bibliotheque{
        .modal-content{
            padding-bottom: 0px;
            border: 0;
        }
    }
}
.modal_students_extra_scolaire_activities_export{
    >div{
        min-width: 600px;
        width: 70%;
        max-width: 100%;
        border-radius: 35px;
        height: 90%;
        min-height: 500px;
        max-height: 90%;
        .modal-content{
            border-radius: 30px;
            height: 100%;
            max-height: 100%;
            padding-bottom: 15px;
            .btn-close{
                z-index: 111;
            }
            .loading{
                position: absolute;
                width: 100%;
                height: 100%;
                .loading_img{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: black;
                    z-index: 111;
                    opacity: 0.2;
                    border-radius: 10px;
                img{
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    max-width: 50%;
                    max-height: 50%;
                }
                }
            }
            >div{

                overflow: auto;

                &::-webkit-scrollbar-track{
                    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                    background-color: #F5F5F5;
                }
                &::-webkit-scrollbar{
                    width: 5px;
                    height: 3px;
                    background-color: #F5F5F5;
                }
                &::-webkit-scrollbar-thumb{
                    background-color: #3eb6ad;
                }
            }
            .modal-header{
                border-top-right-radius: 29px;
                border-top-left-radius: 29px;
                > div{
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    button{
                        margin-right: 0 !important;
                        background: #00b3a9;
                        background-image: none;
                        position: absolute;
                        top: -15px;
                        right: -15px;
                        border: 5px solid #fff !important;
                        width: 40px;
                        height: 40px;
                        text-align: center;
                        color: #fff;
                        border-radius: 50%;
                        font-size: 31px;
                        line-height: 28px;
                        box-shadow: none;
                        background-image: none;
                        font-weight: bold;
                        >span{
                            font-size: 35px !important;
                            font-weight: normal !important;
                            text-shadow: 0 1px 0 #fff !important;
                            line-height: 30px;
                            font-family: inherit;
                        }
                    }
                }
            }
            .modal-body{    
                height: 100%;
                max-height: calc(100% - 56px);
                overflow: auto;
                padding: 30px;
                padding-bottom: 0;
                &::-webkit-scrollbar-track{
                    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                    background-color: #F5F5F5;
                }
                &::-webkit-scrollbar{
                    width: 5px;
                    height: 3px;
                    background-color: #F5F5F5;
                }
                &::-webkit-scrollbar-thumb{
                    background-color: #3eb6ad;
                }
                border-bottom-left-radius: 15px;
                border-bottom-right-radius: 15px;
                .filters.row{
                    padding: 0 20px;
                    label{
                        margin-right: 5px;
                    }
                    >div{
                        margin-right: 15px;
                        margin-bottom: 10px;
                        button{
                            &:after{
                                position: absolute !important;
                                top: 8px;
                            }
                        }
                        >select{
                            width: 220px;
                            position: relative;
                            display: inline-flex;
                            vertical-align: middle;
                            color: #3a3a3a;
                            height: 38px;
                            padding-right: 25px;
                            z-index: 1;
                            background: #fff;
                            border: 1px solid #d1dbe2;
                        }
                    }
                }
                .rows{
                    height: calc(100% - 100px);
                    .col-md-4{
                        padding: 20px;
                    }
                    .students_list{
                        height: calc(100% - 55px);
                        width: 100%;
                        overflow-y: auto;
                        padding-left: 20px;
                        &::-webkit-scrollbar-track{
                            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                            background-color: #F5F5F5;
                        }
                        &::-webkit-scrollbar{
                            width: 5px;
                            height: 3px;
                            background-color: #F5F5F5;
                        }
                        &::-webkit-scrollbar-thumb{
                            background-color: #3eb6ad;
                        }
                        .students_list_table{
                            width: 100%;
                            tr{
                                &.is_child_of_staff{
                                    background-color: #ffe599;
                                }
                                &.header{
                                    th{
                                        background-color: #e7e6e6;
                                        border: 1px solid #7f7f7f;
                                        text-align: center;
                                        font-size: 18px;
                                        padding: 5px;
                                        &.order{
                                            border: 0 solid #7f7f7f;
                                            background-color: transparent;
                                        }
                                        &.student_name{

                                        }
                                        &.student_classe{

                                        }
                                        &.amount{

                                        }
                                        &.payed{

                                        }
                                    }
                                    &.classes{
                                        background-color: #e7e6e6;
                                        th{
                                            background-color: transparent;
                                        }
                                    }
                                }
                                &.total{
                                    background-color: #6afae2;
                                    font-weight: bold;
                                    font-size: 18px;
                                    &.classes{
                                        background-color: #07cb98;
                                    }
                                    td{
                                        height: 50px;
                                    }
                                }
                                td{
                                    border: 1px solid #7f7f7f;
                                    text-align: center;
                                    height: 40px;
                                    padding: 5px;
                                    &.order{
                                        
                                    }
                                    &.student_name{

                                    }
                                    &.student_classe{

                                    }
                                    &.amount{

                                    }
                                    &.payed{
                                        .react-switch-bg{
                                            height: 17px !important;
                                            width: 46px !important;
                                            >div{
                                                height: 17px !important;
                                                width: 28px !important;
                                            }
                                        }
                                        .react-switch-handle{
                                            height: 15px !important;
                                            width: 15px !important;
                                        }
                                        >span{
                                            display: block;
                                        }

                                    }
                                }
                            }
                        }
                    }
                    .line{
                        display: flex;
                        >div{
                            width: 100%;
                        }
                        .textErrors{
                            color: red;
                        }
                        .radioLevels{
                            display: flex;
                            flex-wrap: wrap;
                            .RadioLegend{
                                width: 33%;
                                display: flex;
                                >span{
                                    padding: 0;
                                    margin-right: 5px;
                                    height: max-content;
                                }
                                legend{
                                    margin-bottom: 0;
                                    padding-top: 3px;
                                }
                            }
                        }
                        &.image{
                            >div{
                                text-align: center;
                                img{
                                    max-width: 250px;
                                    max-height: 250px;
                                    width: 250px;
                                    height: 250px;
                                    margin: auto;
                                    border: 1px solid #b7b7b7;
                                    padding: 5px;
                                    border-radius: 50%;
                                    margin: auto;
                                }
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                            }
                        }
                        &.browse_btn{
                            margin-top: 25px;
                            >div{
                                text-align: center;
                                span{
                                    width: max-content;
                                    margin: auto;
                                    background: #00b3a9;
                                    color: white;
                                    cursor: pointer;
                                    padding: 5px 10px;
                                    border-radius: 15px;

                                }
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                            }
                        }
                        &:not(.image):not(.browse_btn){
                            height: 90px;
                            >div{
                                width: 50%;
                                margin: 0 10px;
                                label{
                                    font-size: 20px;
                                }
                                input{
                                    height: 42px;
                                    font-size: 20px;
                                }
                                .selectInput{
                                    height: 42px;
                                    font-size: 20px;
                                }
                                .react-datepicker-wrapper{
                                    height: 42px;
                                    font-size: 20px;
                                    .react-datepicker__input-container{
                                        height: 42px;
                                        font-size: 20px;
                                        &:before{
                                            line-height: 42px;
                                        }
                                        input{
                                            height: 42px;
                                            font-size: 20px;
                                        }
                                    }
                                }
                                .bootstrap-select{
                                    width: 100%;
                                    height: 42px;
                                    >button{
                                        &:after{

                                        }
                                    }
                                }
                            }
                        }
                        .react-datepicker-wrapper{
                            margin-left: 0;
                        }
                        .auto_textarea{
                            textarea{
                                padding: 5px;
                                border-radius: 2.6px;
                                border: 0.6px solid #d1dbe2;
                                background-color: rgba(245,248,249,.3);
                                font-size: 14px;
                                font-weight: 400;
                                color: #1b2437!important;
                                margin-top: 3px;
                                width: 100%;
                            }
                        }
                    }
                    .templates{
                        .drag_div{
                            position: relative;
                            width: 100%;
                            margin: 20px 0px;
                            .template{
                                display: flex;
                                -webkit-box-pack: justify;
                                justify-content: space-between;
                                padding: 0px 5px;
                                .name{
                                    width: calc(100% - 15px);
                                    text-align: left;
                                    display: block;
                                }
                                .remove_template{
                                    color: red;
                                }
                            }
                        }
                    }
                    .video_url,.link_url{
                        display: flex;
                        flex-wrap: wrap;
                        label{
                            margin-right: 10px;
                            width: 80px;
                        }
                        input{
                            width: calc(100% - 90px);
                            margin-bottom: 10px;
                        }
                        button{
                            margin: auto;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
    &.modal_bibliotheque{
        .modal-content{
            padding-bottom: 0px;
            border: 0;
        }
    }
}

.export_params.global{
    >div{
        max-width: 600px;
        max-width: 98%;
        height: 94%;
        margin: 1% 1%;
    }
    .modal-content{
        height: 100%;
        .modal-header{
            justify-content: center;
            font-weight: bold;
        }
        .modal-body{
            &.global{
                height: calc(100% - 110px);
                margin-top: 0;
                padding: 5px;
                width: 98%;
                margin-left: 1%;
                margin-bottom: 10px;
                overflow: unset;
                >.row{
                    margin: 0;
                    .w12{
                        .RadioLegend {
                            width: max-content;
                            padding: 0 5px;
                        }
                    }
                    .select_{
                        min-width: 195px;
                        padding: 0px 10px;
                        label{
                            width: 100%;
                            text-align: left;
                            display: block;
                            font-family: Montserrat;
                            font-size: 15px;
                            font-weight: 500;
                            font-style: normal;
                            font-stretch: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            color: rgb(27, 36, 55);
                            margin-bottom: 5px;
                            margin-top: 5px;
                        }
                    }
                    &.d_f{
                        padding: 0 0px;
                        >label{
                            width: 100%;
                            text-align: left;
                            display: block;
                            font-family: Montserrat;
                            font-size: 15px;
                            font-weight: 500;
                            font-style: normal;
                            font-stretch: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            color: rgb(27, 36, 55);
                            margin-bottom: 5px;
                            margin-top: 5px;
                        }
                    }
                    .rs3__control{
                        border: 0px;
                        background: transparent;
                        box-shadow: none;
                        &:hover{
                          border: 0px;
                        }
                    }
                    .rs3__single-value{
                        background: transparent;
                        color: #FFF;
                        text-align: center
                    }
                    .rs3__indicators{
                        display: none
                    }
                      
                    .rs__menu{
                        box-shadow: 0 5px 45px 0 rgba(153,153,153,0.5);
                        border: 0px;
                        border-radius: 8px;
                        padding: 5px;
                        z-index: 1000;
                        width: max-content; 
                        min-width: 100%;
                        .rs__menu-list{
                          &::-webkit-scrollbar-track{
                            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                            background-color: #F5F5F5;
                            border-radius: 5px;
                          }
                          &::-webkit-scrollbar{
                            width: 10px;
                            /*height: 3px;*/
                            background-color: #F5F5F5;
                            border-radius: 5px;
                          }
                          &::-webkit-scrollbar-thumb{
                            background-color: #3eb6ad;
                            border-radius: 5px;
                          }
                          .rs__option{
                            border-bottom: 1px dashed #ccc;
                            cursor: pointer;
                          }
                          .rs__group{
                            .rs__group-heading{
                              padding: 8px 12px;
                              &:hover{
                                background-color: #86e6df4a;
                                cursor: pointer;
                              }
                            }
                            .rs__option{
                              padding-left: 25px;
                              cursor: pointer;
                            } 
                          }
                        }
                    }
                }
                .viewer{
                    margin-top: 10px;
                    padding: 15px;
                    backdrop-filter: blur(8px);
                    background-color: #dee6eaa3;
                    margin: 0;
                    margin-top: 10px;
                    padding-inline-end: 15px;
                    border-radius: 6px;
                    //padding: 10px 5px;
                    border: 1px solid #d1dbe2;
                    border-image: initial;
                    width: 100%;
                    height: calc(100% - 130px);
                    .table_viewer{
                        height: calc(100%);
                        overflow: auto;
                        padding-right: 5px;
                        background-color: transparent;
                        &::-webkit-scrollbar-track{
                            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                            background-color: #F5F5F5;
                            border-radius: 5px;
                            position: absolute;
                            right: -50; /* Position the scrollbar on the right of the element*/
                        }
                        &::-webkit-scrollbar{
                            width: 10px;
                            height: 10px;
                            background-color: #F5F5F5;
                            border-radius: 5px;
                            position: absolute;
                            right: -50; /* Position the scrollbar on the right of the element*/
                        }
                        &::-webkit-scrollbar-thumb{
                            background-color: #3eb6ad;
                            border-radius: 5px;
                            position: absolute;
                            right: -50; /* Position the scrollbar on the right of the element*/
                        }
                        &.freeze_columns{
                            background: transparent;
                            table{
                                thead{
                                    tr{
                                        th{
                                            position: sticky !important;
                                            top: 0;
                                            z-index: 2;
                                            &.volet_cne{
                                                z-index: 3;
                                            }
                                            &.volet_name{
                                                z-index: 3;
                                            }
                                            &.volet_birthday{
                                                z-index: 3;
                                            }
                                            &.volet_classe_name{
                                                z-index: 3;
                                            }
                                        }
                                    }
                                }
                                tbody{
                                    tr{
                                        td{
                                            &.volet{
                                                position: sticky !important;
                                                left: 0;
                                                z-index: 1;
                                            }
                                            &.empty_td.volet{
                                                background-color: #eaeff1;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .warning{
                        text-align: left;
                        color: red;
                    }
                    table{
                        width: max-content;
                        position: relative;
                        border-collapse: initial;
                        border-spacing: 1px;
                        thead{
                            tr{
                                font-weight: 700;
                                text-align: center;
                                th{
                                    position: relative;
                                    background-image: linear-gradient(180deg,#cad3f2 3%,#b5c4e3 96%);
                                    font-size: 13px;
                                    font-weight: 600;
                                    line-height: 1.64;
                                    color: #202726;
                                    width: auto;
                                    white-space: nowrap;
                                    background-color: #fff;
                                    height: 30px;
                                    
                                    border-image: initial;
                                    border: 1px solid #e8e8e8;
                                    border-right-width: 0;
                                    padding: 5px 22px;
                                    min-width: 110px;
                                    &:first-child{
                                        border-top-left-radius: 6px;
                                        border-bottom-left-radius: 6px;
                                    }
                                    &:last-child{
                                        border-right-width: 1px;
                                        border-top-right-radius: 6px;
                                        border-bottom-right-radius: 6px;
                                    }
                                    &.zindex3{
                                        z-index: 3 !important;
                                    }
                                    .down_up{
                                      position: absolute;
                                      right: 5px;
                                      display: flex;
                                      flex-direction: column;
                                      justify-content: center;
                                      top: 50%;
                                      transform: translateY(-50%);
                                      i{
                                        cursor: pointer;
                                        font-weight: bold;
                                        color: black;
                                        &.deactivated{
                                          color: gray;
                                          cursor: not-allowed;
                                        }
                                      }
                                    }
                                }
                            }
                        }
                        tbody{
                            tr{
                                text-align: center;
                                td{
                                    border-radius: 6px;
                                    font-size: 12px;
                                    font-weight: 500;
                                    line-height: 1.45;
                                    color: black;
                                    width: auto;
                                    white-space: nowrap;
                                    background-color: #fff;
                                    height: 50px;
                                    border-image: initial;
                                    border: 1px solid #e8e8e8;
                                    border-right-width: 0;
                                    padding: 10px;
                                    &.empty_td{
                                        border-color: transparent;
                                        background-color: transparent;
                                    }
                                    &:first-child{
                                        border-top-left-radius: 4px;
                                        border-bottom-left-radius: 4px;
                                    }
                                }
                                &.spacer{
                                    height: 10px;
                                }
                                &.stats_tr.total{
                                    td{
                                        &:not(.empty_td){
                                            background-image: linear-gradient(180deg, #cad3f2 3%, #b5c4e3 96%);
                                        }
                                        &.empty_td{
                                            background-image: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.modal.export_students_tutors_massar{
    z-index: 111050;
    background: #06060645;
}

.text-decoration-line-through{
    text-decoration: line-through;
}
@media (min-width: 1300px){
    .col-25{
        flex: 0 0 20%!important;
        max-width: 20%!important;
        // position: relative;
        // width: 100%;
        // padding-right: 15px;
        // padding-left: 15px;
    }
}

.scholar_years_students.modal{
    .modal-xl{
        max-width:1400px;
    }
} 

// .modal-backdrop{
//     z-index: 1050!important;
// }