/************* Main *************/
div.evaluation_page {
    margin: 25px 30px;
    /*width: calc(100% - 95px);
    float: right;
    padding: 1.6rem;*/

    .top_bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        border-radius: 5px;

        @media (max-width: 767px) {
            flex-wrap: wrap;
        }

        .bar_left {
            display: flex;
            align-items: center;

            .bar_img {
                display: flex;
                justify-content: center;
                align-items: center;

                width: 40px;
                height: 40px;
                background-color: $primary_green;
                padding: 0.3rem 0.4rem;
                border-radius: 5px;
                @include green_shadow;

                img {
                    width: 95%;
                }
            }

            .nav-link {
                padding: 0;
                position: relative;
                padding-right: 20px;
                &:after {
                    content: '';
                    position: absolute;
                    width: 2px;
                    top: 0;
                    right: 0;
                    height: 100%;
                    background: #f5f6fa;
                }
            }

            .bar_title {
                font-size: 1rem;
                margin: 0;
                margin-left: 1rem;
            }
        }

        .bar_right {
            display: flex;
            align-items: center;
            @media (max-width: 767px) {
                margin-top: 10px;
                flex-basis: 100% !important;
                display: flex;
                background: inherit !important;
                flex-wrap: wrap;
            }

            .bar_search {
                .input-group {
                    border-radius: 30px;
                    overflow: hidden;
                    border: 1px solid #dfe3e8;

                    input {
                        height: 1.6rem;
                        border: none;
                        border-radius: 0;
                        background-color: #f8f8f8;
                        font-size: 0.9rem;

                        &:focus {
                            box-shadow: none;
                        }

                        &::placeholder {
                            color: #b1bfcd;
                            font-size: 0.7rem;
                            position: relative;
                            bottom: 0.1rem;
                        }
                    }

                    button {
                        border-radius: 0;
                        border: none;
                        box-shadow: none;
                        background-color: #f8f8f8;
                        padding: 0px 10px;
                    }
                }
            }

            .bar_ham {
                margin: 0 1.2rem;
            }

            .bar_filter {
                font-size: 0.9rem;

                .bar_select {
                    margin-right: 0.5rem;
                }

                .input-group-text {
                    padding-left: 0;
                }

                .custom-select {
                    padding-left: 0.2rem;
                    cursor: pointer;
                    font-size: .9rem;
                    font-weight: 500;
                    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='255px' height='255px' fill='%23495057' viewBox='0 0 255 255' style='enable-background:new 0 0 255 255;' xml:space='preserve'%3E%3Cg%3E%3Cg id='arrow-drop-down'%3E%3Cpolygon points='0,63.75 127.5,191.25 255,63.75'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E%0A");
                }
            }
            .bar_select{
                @media (max-width: 767px) {
                    max-width: inherit !important;
                    // &:nth-child(6){
                    //     display: none;
                    // }
                }
                .input-group{
                    @media (max-width: 767px) {
                        margin-bottom: 10px;
                    }
                    label{
                        @media (max-width: 767px) {
                            background-color: inherit;
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }

    .bar_search_mobile,
    .challenge_mobile_filter {
        display: none;
    }

    .footer_btns {
        margin-top: 2rem;
        text-align: center;

        .btn-yellow {
            margin-right: 2rem;
            padding: 0.7rem 2rem;
            font-size: 0.7rem;

            span {
                margin-left: 0.5rem;
            }
        }
    }
}
div.annual_reviews_page {
    margin: 25px 30px;
    /*width: calc(100% - 95px);
    float: right;
    padding: 1.6rem;*/

    .top_bar {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-color: #fff;
        border-radius: 5px;

        @media (max-width: 767px) {
            flex-wrap: wrap;
        }

        .bar_right {
            display: flex;
            align-items: center;
            @media (max-width: 767px) {
                margin-top: 10px;
                flex-basis: 100% !important;
                display: flex;
                background: inherit !important;
                flex-wrap: wrap;
            }
            .bar_select{
                @media (max-width: 767px) {
                    max-width: inherit !important;
                    // &:nth-child(6){
                    //     display: none;
                    // }
                }
                .input-group{
                    @media (max-width: 767px) {
                        margin-bottom: 10px;
                    }
                    label{
                        @media (max-width: 767px) {
                            background-color: inherit;
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }

    .bar_search_mobile{
        display: none;
    }

    .footer_btns {
        margin-top: 2rem;
        text-align: center;

        .btn-yellow {
            margin-right: 2rem;
            padding: 0.7rem 2rem;
            font-size: 0.7rem;

            span {
                margin-left: 0.5rem;
            }
        }
    }
}

/************* Floating Sidebar *************/
.float_side {
    position: fixed;
    right: 0;
    height: 100%;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .f_side_item {
        width: 100%;
        @include green_shadow;
        @include green_grad;
        margin-bottom: 0.3rem;
        display: flex;
        flex-direction: column;
        padding: 0.5rem 0.3rem;
        color: #fff;
        align-items: center;
        border-radius: 5px 0 0 5px;

        img {
            width: 40%;
            margin-bottom: 0.5rem;
        }

        span {
            font-size: 0.6rem;
        }
    }
}

@media only screen and (max-width:1024px) {
    div.main {
        &.challenges-page {
            .challenge_grid {
                .challenge {
                    flex-basis: 23%;
                }
            }
        }
    }
}

@media only screen and (max-width:991px) {
    .select2-container--default{
        max-width: 70px;
        .select2-selection--single{
            background: transparent;
            .select2-selection__rendered{
                font-size: 0.8rem !important;
            }
        }
    }
    div.main {
        &.challenges-page {
            .top_bar {
                .bar_left {
                    display: flex;
                    align-items: center;
                    flex-basis: 44%;
                    justify-content: space-between;
                    padding-right: 5px;

                    >div {
                        display: flex;
                        align-items: center;

                        &.download_upload_icons a {
                            margin-right: 10px;
                        }
                    }
                }

                .bar_right .bar_filter .bar_select {
                    margin-right: 0;
                    label{
                        font-size: 0.8rem;
                    }
                }
            }

            .challenge_grid {
                .challenge {
                    padding-top: 0;

                    &:nth-of-type(4) {
                        margin-right: 0;
                    }

                    .c_title {
                        margin-bottom: 3px;
                    }

                    .grades {
                        font-size: 0.7rem;
                        margin-bottom: 0.5rem;
                    }

                    .c_footer {
                        .c_icons {
                            img {
                                max-width: 15px;
                                width: auto;
                            }
                        }

                        .c_btn button {
                            font-size: 9px;
                            padding: 2px 15px 3px;
                        }
                    }

                    .all_btn {
                        margin: 0.5rem 0;

                        .btn {
                            padding: 2px 11px;
                            font-size: 10px;
                        }
                    }
                }
            }
        }
    }

    #create_class_group_modal {
        background: rgba(177, 191, 205, 0.84);

        .modal-dialog {
            max-width: 280px;
            margin: auto;

            .modal-content .modal-body {
                padding: 20px;
                text-align: center;

                .footer_btns {
                    a {
                        margin: 10px 0;
                        padding: 13px 23px;

                        * {
                            color: #fff;
                        }

                        i {
                            margin-right: 10px;
                            font-size: 17px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    div.main {
        &.challenges-page {
            padding-top: 0;

            .top_bar {
                background: transparent;

                .bar_right {
                    .bar_search .input-group {
                        border: 0;

                        button {
                            padding: 0;
                            padding-left: 4px;
                            font-size: larger;
                        }
                    }

                    .bar_select label.input-group-text {
                        background: transparent;
                    }

                    .challenge_mobile_filter {
                        position: absolute;
                        width: 120px;
                        right: 0;
                        font-size: 12px;
                        background: #fff;
                        z-index: 11;
                        border-radius: 4px;
                        top: 105%;
                        box-shadow: 0 0 10px #bbb;
                        padding: 10px 0;

                        &.show_mobile_filter {
                            display: block;
                        }

                        ul {
                            padding: 0;
                            margin: 0;

                            li {
                                display: block;

                                a {
                                    color: #333;
                                    font-weight: 500;
                                    padding: 7px 17px;
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }

            .bar_search_mobile {
                &.show_mobile_search {
                    display: block;
                }

                .input-group {
                    border-radius: 30px;
                    overflow: hidden;
                    border: 1px solid #dfe3e8;
                    background: rgb(248, 248, 248);
                    padding: 0;
                    max-width: 90%;
                    margin: 20px auto;

                    input {
                        font-size: 12px;
                        height: 28px;
                        padding: 0 16px;
                        background: transparent;
                        border: 0;
                    }
                }

                button {
                    padding: 0;
                    width: 40px;
                    background: transparent;

                    img {
                        max-width: 14px
                    }
                }
            }

            .challenge_grid {
                justify-content: space-between;

                .challenge {
                    flex-basis: 48%;
                    margin-right: 0;

                    img.medal {
                        height: auto;
                        margin: 30px 0 20px;
                        max-height: 75px;
                    }
                }
            }
        }
    }
}

.evaluation_page {

    // common bar select
    .top_bar .bar_left {
        >div {
            align-items: center;
        }
    }

    .bar_left {
        .nav {
            width: 100%;

            .nav-item {
                margin-right: 5px;
                margin-top: 2.5px;
                margin-bottom: 2.5px;
                a {
                    padding-right: 5px;
                    >div {
                        align-items: center;

                        .bar_title {
                            margin: 0;
                            float: left;
                            font-family: Montserrat;
                            font-size: 13px;
                            font-weight: 500;
                            font-style: normal;
                            font-stretch: normal;
                            line-height: normal;
                            -webkit-letter-spacing: normal;
                            -moz-letter-spacing: normal;
                            -ms-letter-spacing: normal;
                            letter-spacing: normal;
                            color: #1b2437;
                            margin: 0 0 0 5px;
                            text-decoration: none;
                            background-color: transparent;
                        }
                    }

                    img {
                        background: #b1bfcd;
                        padding: 6px 3px;
                        width: 28px;
                        height: 35px;
                        float: left;
                        border-radius: 4px;
                        vertical-align: middle;
                        text-align: center;
                        line-height: 35px;
                    }

                    &.active{
                        img {
                            background-color: #5acac3;
                            box-shadow: 0 0 20px 0 rgba(90,202,195,0.5);
                        }
                        .bar_title {
                            color: #80c9ca;
                        }
                    }
                }
            }
        }
    }

    .evaluation_container {
        background-color: #fff;
        margin-top: 1rem;
        padding: 1rem 1.9rem 20px;
        border-radius: 5px;
        &.parent_view{
            @media only screen and (max-width:1199px) {
                padding-bottom: 60px !important;
            }
        }

        .e_top_bar {
            display: flex;
            justify-content: space-between;
            font-size: 0.8rem;
            flex-wrap: wrap;
            .choose_view{
                width: 100%;
                margin-bottom: 10px;
                >div{
                    width: 100%;
                    display: flex;
                    flex-direction: inherit;
                    justify-content: center;
                    .legend{
                        width: auto;
                        line-height: 30px;
                    }
                    .choices{
                        margin-left: 15px;
                        flex-direction: row;
                        display: flex;
                        flex-wrap: wrap;
                        border: 2px solid #d7d5d5;
                        border-radius: 9px;
                        width: 250px;
                        >span{
                            padding: 5px;
                            display: block;
                            max-width: 124px;
                            width: 50%;
                            color: #51c4bc;
                            background-color: white;
                            text-align: center;
                            cursor: pointer;
                            border-radius: 6px;
                            &.active{
                                color: white;
                                background-color: #51c4bc;
                            }
                        }
                        &.control{
                            width: 320px;
                            >span{
                                padding: 5px;
                                max-width: 106.6px;
                                width: 33.33%;
                            }
                        }
                    }
                    *{
                        margin-bottom: 0;
                    }
                }
            }
            .e_top_left {
                min-width: 100px;
                display: flex;
                align-items: center;

                .student_info {
                    position: relative;
                    .student_label {
                        color: black;
                    }

                    img {
                        margin: 0 0.6rem;
                        width: 30px;
                        height: 30px;
                        border-radius: 4px;
                    }

                    .student_name {
                        font-weight: 500;
                        border: none;
                        width: 50%;
                    }
                    .student-name{
                        font-size: 0.9rem;
                        font-weight: 500;
                        box-shadow: none;
                        cursor: pointer;
                    }
                    .custom-dropdown{
                        position: absolute;
                        border-radius: 10px;
                        background-color: #ffffff;
                        margin-top: 1rem;
                        display: none;
                        width: max-content;
                        left: 15%;
                        z-index: 20;
                        .student-names-dropdown{
                            // border: 1px solid black;
                            padding: 1rem;
                            box-shadow: 0 5px 45px 0 rgba(153, 153, 153, 0.5);
                            position: relative;
                            background-color: #ffffff;
                                border-radius: 10px;
                            
                            overflow: auto;
                            &::-webkit-scrollbar-track{
                                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                                background-color: #F5F5F5;
                            }
                            &::-webkit-scrollbar{
                                width: 5px;
                                height: 3px;
                                background-color: #F5F5F5;
                            }
                            &::-webkit-scrollbar-thumb{
                                background-color: #3eb6ad;
                            }
                            .form-group{
                                position: relative;
                                input{
                                    border-radius: 25px;
                                    border: 1px solid #c2c2c2;
                                    width: 100%;
                                    height: 1.6rem;
                                    background-color: rgba(177, 191, 205, 0.1);

                                    &::placeholder{
                                        font-size: 10px;
                                    }
                                }
                                span{
                                    position: absolute;
                                    top: 10px;
                                    right: 12px;
                                }
                            }

                            .student-detail{
                                display: flex;
                                align-items: center;
                                border-bottom: 1px solid rgba(195, 195, 195, 0.3);
                                padding-bottom: 10px;
                                padding-top: 10px;
                                cursor: pointer;
                                min-width: 80px;
                                p{
                                    font-size: .9rem;
                                    padding-left: 1rem;
                                    margin-bottom: 0;
                                    font-weight: 500;
                                }

                                &:focus{
                                     box-shadow: 0 5px 45px 0 rgba(153, 153, 153, 0.5);
                                }

                                &:hover{
                                     box-shadow: 0 5px 45px 0 rgba(153, 153, 153, 0.5);
                                     border-radius: 10px;
                                     border: 1px solid #c2c2c2;
                                }
                            }

                            
                        }
                        .student-names-dropdown:before{
                            content: "";
                            border-bottom: 15px solid #fff;
                            border-left: 7px solid transparent;
                            border-right: 7px solid transparent;
                            position: absolute;
                            top: -15px;
                            left: 45%;
                            box-shadow: 0 5px 45px 0 rgba(153, 153, 153, 0.5);
                    }
                    }       
                }
            }

            .e_top_right {
                display: flex;
                margin-right: 2rem;
                .btn{
                    display: flex;
                    align-items: center;
                }
                .checkbox_style{
                    .pretty{
                        .state{
                            label{
                                display: block;
                                font-family: Montserrat;
                                font-size: 15px;
                                font-weight: 500;
                                font-style: normal;
                                font-stretch: normal;
                                line-height: normal;
                                letter-spacing: normal;
                                color: #1b2437;
                                margin-bottom: 5px;
                                &::after, &::before{
                                    top: 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        // circle gradients
        .circ {
            height: 22px;
            width: 22px;
            border-radius: 100%;
            margin: 0 auto;
            display: inline-block;

            &.green {
                background-image: linear-gradient(223deg, #72dcd6, #3eb6ad);
            }

            &.yellow {
                background-image: linear-gradient(220deg, #fdd271, #d5a433);
            }

            &.orange {
                background-image: linear-gradient(41deg, #ff5f04, #ff867f);
            }

            &.gray {
                background-image: linear-gradient(41deg, #90a1b1, #b1bfcd);
            }

            &.white {
                background-image: linear-gradient(41deg, #fff, #fff);
            }
        }

        .star {
            background-image: url('../img/evaluation/star_icon.svg');
            height: 22px;
            width: 22px;
            display: block;
            margin: 0 auto;
            background-size: contain;
            background-position: center;

            &.white {
                background-image: url('../img/evaluation/star_white_icon.svg');
            }
        }

        .e_single_table {
            background: rgba(245, 248, 249, 0.3);
            border-radius: 8px;
            border: 1px solid #d1dbe2;
            padding: 10px;
            margin-top: 30px;

            >label {
                font-size: 14px;
                color: #5a5a5a;
                font-weight: 600;
            }
            //
            .e_single_table_wrap{
                border-radius: 0px;
                border-right-width: 0px;
                table{
                    border: 1px solid #d1dbe2;
                }
                .classe_average_tr{
                    td{
                        @media (max-width: 1199px){
                            &:first-of-type{
                                width: 95% !important;
                            }
                        }
                        @media (max-width: 767px){
                            &:first-of-type{
                                width: 85% !important;
                            }
                        }
                    }
                    &.MC{
                        td{
                            &.mc{
                                font-size: 10px;
                            }
                            @media (max-width: 1199px){
                                &:first-of-type{
                                    width: 95% !important;
                                }
                            }
                            @media (max-width: 767px){
                                &:first-of-type{
                                    width: 85% !important;
                                }
                            }
                        }
                    }
                    &.student_view{
                        td{
                            @media (max-width: 767px){
                                &:first-of-type{
                                    width: 80% !important;
                                }
                            }
                        }
                    }
                }
                tr.std_view{
                    td{
                        @media (max-width: 1199px){
                            &:first-of-type{
                                width: 95% !important;
                            }
                        }
                        @media (max-width: 767px){
                            &:first-of-type{
                                width: 80% !important;
                            }
                        }
                    }
                    &.MC{
                        td{
                            @media (max-width: 1199px){
                                &:first-of-type{
                                    width: 95% !important;
                                }
                            }
                            @media (max-width: 767px){
                                &:first-of-type{
                                    width: 80% !important;
                                }
                            }
                        }
                    }
                }
                .code_massar, .std_name{
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    .down_up{
                      position: absolute;
                      right: 5px;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      top: 50%;
                      transform: translateY(-50%);
                      i{
                        cursor: pointer;
                        font-weight: bold;
                        color: black;
                        &.white{
                            color: white;
                        }
                        &.deactivated{
                          color: gray;
                          cursor: not-allowed;
                        }
                      }
                    }
                }
                .std_name{
                    width: 210px;
                    &.head{
                        width: 250px;
                        text-align: center;
                        justify-content: center;
                        font-weight: bold;
                        color: black;
                    }
                }
                .code_massar{
                    font-weight: normal;
                    width: 130px;
                    &.head{
                        font-weight: bold;
                        text-align: center;
                        line-height: 15px;
                    }
                }
            }
            table {
                font-size: 14px;
                color: rgba(27, 36, 55, 0.5);
                font-weight: 500;
                table-layout: fixed;
                border-collapse: collapse;
                border-radius: 8px;
                &:not(.min_w_100):not(.all_competencies_table):not(.all_objectifs_table){
                    width: 100%;
                }
                // modif
                td,
                th {
                    border-right: 1px solid #d1dbe2;
                    border-bottom: 1px solid #d1dbe2;
                    padding: 1px 4px;
                    background: #fff;
                    color: black;
                    font-size: 0.8rem;
                    background-clip: padding-box;
                    height: 47px;
                    >.down_up{
                      position: absolute;
                      right: 5px;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      top: 50%;
                      transform: translateY(-50%);
                      i{
                        cursor: pointer;
                        font-weight: bold;
                        color: black;
                        &.white{
                            color: white;
                        }
                        &.deactivated{
                          color: gray;
                          cursor: not-allowed;
                        }
                      }
                    }
                    >.show_hide_obj{
                      position: absolute;
                      left: 5px;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      top: 50%;
                      transform: translateY(-50%);
                      i{
                        cursor: pointer;
                        font-weight: bold;
                        color: black;
                        &.white{
                            color: white;
                        }
                        &.deactivated{
                          color: gray;
                          cursor: not-allowed;
                        }
                      }
                    }
                    >.control_name{
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        width: 100%;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        white-space: unset!important;
                        margin-top: -9px;
                        color: white;
                        padding-right: 7px;
                        padding-left: 7px;
                        font-size: 10px;
                        line-height: 10px;
                        height: 20px;
                        margin-top: 5px;
                        margin-bottom: -5px;
                    }
                    >.control_name2{
                        line-height: 10px;
                    }
                    &:not(:first-child):not(:last-child) {
                        color: #333;
                        font-weight: bold;
                        // @media (min-width: 768px) and (max-width: 999px) {
                        //     width: 7%;
                        // }
                        // @media (min-width: 1000px) and (max-width: 1199px) {
                        //     width: 5%;
                        // }
                        width: inherit;
                    }

                }
                td{

                    &:not(:first-child):not(:last-child) {
                        text-align: center;
                        select{
                            text-align: center;
                        }
                    }
                }
                input {
                    font-weight: bold;
                    width: 100%;
                    border: 0;
                    text-align: center;
                    -moz-appearance: textfield;
                    background: transparent;
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                    }
                    &:focus{
                        outline: 2px solid #a9f3ed;
                    }
                }
                tr:last-of-type{
                    td, th{
                        border-bottom:0;
                    }
                }

                tr{
                    td {
                        line-height: 30px;
                        position: relative;
                        &:first-of-type {
                            width: 90%;
                        }
                        &:not(:first-of-type) {
                            text-align: center;
                        }

                        &:last-of-type, &.gradient_block {
                            text-align: center;
                            @include green_grad_important;
                            // background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
                            color: #fff !important;
                            border-right:0;
                            &.w_0{
                                width: 0!important;
                            }
                            &.gradient_block2{
                                background-image: linear-gradient(45deg, #9e3eb6, #d7a3e4) !important;
                            }
                        }
                        &.back_inh{
                            background-image: inherit !important;
                            color: black !important;
                            font-weight: bold !important;
                        }

                        &.grey_grad {
                            background: #b1bfcd;
                        }

                        &.yellow_grad {
                            @include yellow_grad;
                            color: #fff !important;
                            text-align: center;
                        }

                        &.orange_grad {
                            @include orange_grad;
                        }
                        &.info_message{
                            width: 100% !important;

                            background: transparent !important;
                            text-align: left !important;
                            color: #000000 !important;
                        }

                        &:not(:first-of-type) {
                            @media (max-width: 1199px) {
                                pointer-events: none;
                            }
                        }
                        .error{
                            display: none;
                            .span_err{
                                position: absolute;
                                right: 123%;
                                width: max-content;
                                color: red;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }
                        &:hover{
                            .error{
                                display: block;
                            }
                        }
                    }
                    &.control{
                        td:not(.td_action):last-of-type{
                            // width: 5%;
                            // @media (max-width: 1199px) {
                            //     width: 10%;
                            // }
                            width: inherit;
                        }
                        td.td_action{
                            background: transparent;
                            &.disabled{
                                background-color: #c4c4c4;
                            }
                        }
                        td.parent_view_mc{
                            background: #b1bfcd;
                            width: inherit !important;
                        }
                        td.child_note{
                            text-align: center;
                            @include green_grad;
                            // background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
                            color: #fff !important;
                            border-right:0;
                        }
                        .delete_cotrol_prof{
                            position: absolute;
                            right: 0;
                        }
                    }
                    &.appreciation{
                        td:first-of-type{
                            width: 30% !important;
                            @media (max-width: 1199px) {
                                width: 3% !important;
                                padding: 0px !important;
                                padding-left: 5px !important;
                                div{
                                    float: none !important;
                                }
                            }
                        }
                        td.td_appr_types{
                            width: auto !important;
                        }
                        .appreciation_mobile{
                            position: relative;
                            .appreciation_input{
                                text-align: left;
                                &.no_100{
                                    width: calc(100% - 13px);
                                }
                            }
                            a{
                                display: none;
                                position: absolute;
                                right: -2px;
                                top: 50%;
                                transform: translateY(-50%);
                                @media (max-width: 1199px) {
                                    display: block;
                                }
                            }
                            span.inline{
                                display: inline-block !important;
                            }
                        }
                        td:last-of-type{
                            width: 5%;
                        }
                    }
                    &.competency{
                        td:first-of-type{
                            @media (max-width: 767px) {
                                width: 85% !important;
                            }
                        }
                    }
                    &.mobile_appr{
                        td {
        
                            &:last-of-type{
                                color: black !important;
                            }
                        }
                    }
                }

                select {
                    border: 0;
                    background-color: transparent;
                    padding: 0px 12px 0 0px;
                    outline: none;
                    background-position: right center;
                    box-shadow: none !important;
                    font-weight: 700;
                    font-size: 17px;
                    //background-image: url('../../assets/images/evaluation/evaluation-select-arrow.svg');
                    &:focus{
                        outline: 2px solid #a9f3ed;
                    }
                }

                .e_dropdown {
                    text-align: center;
                    position: relative;

                    span{
                        display: inline-block;
                        vertical-align: middle;
                        width: 22px;
                        height: 22px;
                    }

                    .down_arrow {
                        //background-image: url('../../assets/images/evaluation/evaluation-select-arrow.svg');
                        position: absolute;
                        z-index: 90;
                        right: -10px;
                        top: 40%;
                        width: 10px;
                        height: 5px;
                    }

                    .dropdown-toggle {
                        padding: 0;
                        background-color: transparent;

                        &::after {
                            content: unset;
                        }

                        &:focus {
                            box-shadow: none;
                        }

                    }

                    .dropdown-menu {
                        min-width: 2.5rem;

                        .dropdown-item {
                            padding: .25rem 0;
                            display: flex;
                        }
                    }
                }
                &.all_competencies_table, &.all_objectifs_table, &.all_controls_notes{
                    tr{
                        td{
                            pointer-events: inherit;
                        }
                    }
                }
            }
            .e_single_table_wrap.appriciations{
                table{
                    table-layout: inherit;
                }
                &.td_w_23{
                    table{
                        tr.appreciation td{
                            &:first-of-type{
                                width: 23% !important;
                            }
                            &.td_appr_types{
                                width: auto !important;
                            }
                        }
                    }
                }
            }

            .table-bottom-btn {
                a {
                    &.saved-control{
                        background: #4ec2ba;
                    }
                    color: #fff;
                    background: #b1bfcd;
                    padding: 7px 10px;
                    display: inline-block;
                    border-radius: 49px;
                    font-size: 14px;
                    max-width: 165px;
                    margin: 12px 0 5px;
                    width: 100%;
                    font-weight: 600;

                    span {
                        margin-right: 15px;
                    }
                }
            }
        }

        .evaluation-bottom-notice {
            text-align: center;
            margin-top: 40px;

            ul {
                margin-bottom: 0;

                li {
                    padding: 0 10px;
                    display: inline-block;
                    color: #7f8291;
                    font-size: 14px;

                    label {
                        font-weight: 600;
                        margin-bottom: 0;
                    }
                }
            }
        }

        &.evaluation_with_sidebar {
            .e_single_table table tr td:first-of-type {
                width: 68%;
            }
        }

        &.score_assessment {
            .e_single_table {
                table {
                    tr td {
                        text-align: center;
                        padding: 12px 15px;

                        &:first-of-type {
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
    &.print_view_page{
        width: 100%;
        float: none;
        padding: 0.5rem;
        margin: 2rem auto;
        .ph_top_left{
            background-color: #b1bfcd;
            border-radius: 100%;
            padding: 0;
            color: #fff;
            height: 35px;
            width: 35px;
            line-height: 35px;
            margin-top: 0.3rem;
            text-align: center;
            position: absolute;
        }
        .print_container{
            padding: 25px;
            table{
                border: 1px solid #d1dbe2;
                .header_table{
                    td{
                        border: 0;
                        border-bottom: 1px solid #d1dbe2;
                    }
                }
            }
        }
        .print_view_header{
            display: flex;
            flex-direction: column;
            align-items: center;
            .print_header_top{
                display: flex;
                align-items: flex-start;
                justify-content: center;
                width: 100%;
                .ph_top_left{
                    background-color: transparent;
                    border-radius: 100%;
                    padding: 0;
                    color: #fff;
                    height: 35px;
                    width: 35px;
                    line-height: 35px;
                    margin-top: 0.3rem;
                    text-align: center;
                }
                .ph_top_middle{
                    > label{
                        background: #fff;
                        padding: 0.4rem 4rem;
                        font-weight: 500;
                        font-size: 18px;
                        border-radius: 4px;
                    }
                    .ph_top_middle_bottom{
                        display: flex;
                        justify-content: space-around;
                        font-size: 0.8rem;
                        padding-right: 0;
                        margin-top: 8px;
                        font-weight: 500;
                        label{
                            color: #a7a7a7;
                        }
                    }
                }
                .ph_top_right{
                    margin-top: 0.4rem;
                    .btn{
                        padding: 0.4em 2rem;
                    }
                }
            }
        }
        .evaluation_container {
            background-color: transparent;
            padding: 0;
             .e_single_table {
                background: transparent;
                border: 0;
                padding: 0;
                table{
                    td{
                        text-align: center;
                        &:first-of-type{
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}

.annual_reviews_page {


    .annual_reviews_container {
        background-color: #fff;
        margin-top: 1rem;
        padding: 1rem 1.9rem 20px;
        border-radius: 5px;
        &.parent_view{
            @media only screen and (max-width:1199px) {
                padding-bottom: 60px !important;
            }
        }

        .e_top_bar {
            display: flex;
            justify-content: space-between;
            font-size: 0.8rem;
            flex-wrap: wrap;
            .choose_view{
                width: 100%;
                margin-bottom: 10px;
                >div{
                    width: 100%;
                    display: flex;
                    flex-direction: inherit;
                    justify-content: center;
                    .legend{
                        width: auto;
                        line-height: 30px;
                    }
                    .choices{
                        margin-left: 15px;
                        flex-direction: row;
                        display: flex;
                        flex-wrap: wrap;
                        border: 2px solid #d7d5d5;
                        border-radius: 9px;
                        width: 250px;
                        >span{
                            padding: 5px;
                            display: block;
                            max-width: 124px;
                            width: 50%;
                            color: #51c4bc;
                            background-color: white;
                            text-align: center;
                            cursor: pointer;
                            border-radius: 6px;
                            &.active{
                                color: white;
                                background-color: #51c4bc;
                            }
                        }
                        >div{
                            .react-switch-bg{
                                height: 22px;
                                width: 51px;
                                >div{
                                    height: 22px;
                                }
                            }
                            .react-switch-handle{
                                height: 20px;
                                width: 20px;
                            }
                        }
                    }
                    *{
                        margin-bottom: 0;
                    }
                }
            }
            .e_top_left {
                min-width: 100px;
                display: flex;
                align-items: center;

                .student_info {
                    position: relative;
                    .student_label {
                        color: black;
                    }

                    img {
                        margin: 0 0.6rem;
                        width: 30px;
                        height: 30px;
                        border-radius: 4px;
                    }

                    .student_name {
                        font-weight: 500;
                        border: none;
                        width: 50%;
                    }
                    .student-name{
                        font-size: 0.9rem;
                        font-weight: 500;
                        box-shadow: none;
                        cursor: pointer;
                    }
                    .custom-dropdown{
                        position: absolute;
                        border-radius: 10px;
                        background-color: #ffffff;
                        margin-top: 1rem;
                        display: none;
                        width: max-content;
                        left: 15%;
                        z-index: 20;
                        .student-names-dropdown{
                            // border: 1px solid black;
                            padding: 1rem;
                            box-shadow: 0 5px 45px 0 rgba(153, 153, 153, 0.5);
                            position: relative;
                            background-color: #ffffff;
                                border-radius: 10px;
                            
                            overflow: auto;
                            &::-webkit-scrollbar-track{
                                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                                background-color: #F5F5F5;
                            }
                            &::-webkit-scrollbar{
                                width: 5px;
                                height: 3px;
                                background-color: #F5F5F5;
                            }
                            &::-webkit-scrollbar-thumb{
                                background-color: #3eb6ad;
                            }
                            .form-group{
                                position: relative;
                                input{
                                    border-radius: 25px;
                                    border: 1px solid #c2c2c2;
                                    width: 100%;
                                    height: 1.6rem;
                                    background-color: rgba(177, 191, 205, 0.1);

                                    &::placeholder{
                                        font-size: 10px;
                                    }
                                }
                                span{
                                    position: absolute;
                                    top: 10px;
                                    right: 12px;
                                }
                            }

                            .student-detail{
                                display: flex;
                                align-items: center;
                                border-bottom: 1px solid rgba(195, 195, 195, 0.3);
                                padding-bottom: 10px;
                                padding-top: 10px;
                                cursor: pointer;
                                min-width: 80px;
                                p{
                                    font-size: .9rem;
                                    padding-left: 1rem;
                                    margin-bottom: 0;
                                    font-weight: 500;
                                }

                                &:focus{
                                     box-shadow: 0 5px 45px 0 rgba(153, 153, 153, 0.5);
                                }

                                &:hover{
                                     box-shadow: 0 5px 45px 0 rgba(153, 153, 153, 0.5);
                                     border-radius: 10px;
                                     border: 1px solid #c2c2c2;
                                }
                            }

                            
                        }
                        .student-names-dropdown:before{
                            content: "";
                            border-bottom: 15px solid #fff;
                            border-left: 7px solid transparent;
                            border-right: 7px solid transparent;
                            position: absolute;
                            top: -15px;
                            left: 45%;
                            box-shadow: 0 5px 45px 0 rgba(153, 153, 153, 0.5);
                    }
                    }       
                }
            }

            .e_top_right {
                display: flex;
                margin-right: 2rem;
                .btn{
                    display: flex;
                    align-items: center;
                }
            }
        }

        // circle gradients
        .circ {
            height: 22px;
            width: 22px;
            border-radius: 100%;
            margin: 0 auto;
            display: inline-block;

            &.green {
                background-image: linear-gradient(223deg, #72dcd6, #3eb6ad);
            }

            &.yellow {
                background-image: linear-gradient(220deg, #fdd271, #d5a433);
            }

            &.orange {
                background-image: linear-gradient(41deg, #ff5f04, #ff867f);
            }

            &.gray {
                background-image: linear-gradient(41deg, #90a1b1, #b1bfcd);
            }

            &.white {
                background-image: linear-gradient(41deg, #fff, #fff);
            }
        }

        .star {
            background-image: url('../img/evaluation/star_icon.svg');
            height: 22px;
            width: 22px;
            display: block;
            margin: 0 auto;
            background-size: contain;
            background-position: center;

            &.white {
                background-image: url('../img/evaluation/star_white_icon.svg');
            }
        }

        .a_r_single_table {
            background: rgba(245, 248, 249, 0.3);
            border-radius: 8px;
            border: 1px solid #d1dbe2;
            padding: 10px;
            margin-top: 30px;

            >label {
                font-size: 14px;
                color: #5a5a5a;
                font-weight: 600;
            }
            //
            .a_r_single_table_wrap{
                border-radius: 0px;
                border-right-width: 0px;
                table{
                    border: 1px solid #d1dbe2;
                }
            }
            table {
                font-size: 14px;
                color: rgba(27, 36, 55, 0.5);
                font-weight: 500;
                table-layout: fixed;
                border-collapse: collapse;
                width: 100%;
                border-radius: 8px;

                // modif
                td,
                th {
                    border-right: 1px solid #d1dbe2;
                    border-bottom: 1px solid #d1dbe2;
                    padding: 1px 4px;
                    background: #fff;
                    font-size: 0.8rem;
                    background-clip: padding-box;
                    height: 47px;
                    color: black;
                    &.criteria_name{
                        color: #adadad;
                    }

                }
                td{
                    &:not(:first-child):not(:last-child) {
                        text-align: center;
                        select{
                            text-align: center;
                        }
                    }
                }
                input {
                    font-weight: bold;
                    width: 100%;
                    border: 0;
                    text-align: center;
                    -moz-appearance: textfield;
                    background: transparent;
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                    }
                    &:focus{
                        outline: 2px solid #a9f3ed;
                    }
                }

                tr{
                    td {
                        line-height: 30px;
                        position: relative;
                        &:not(:first-of-type) {
                            text-align: center;
                        }
                        &.info_message{
                            width: 100% !important;

                            background: transparent !important;
                            text-align: left !important;
                            color: #b0bfcd !important;
                        }

                        &:not(:first-of-type) {
                            @media (max-width: 1199px) {
                                pointer-events: none;
                            }
                        }
                        .error{
                            display: none;
                            .span_err{
                                position: absolute;
                                right: 123%;
                                width: max-content;
                                color: red;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }
                        &:hover{
                            .error{
                                display: block;
                            }
                        }
                    }
                }
                thead{
                    td{
                        font-weight: bold;
                        color: black;
                        text-align: center;
                    }
                }
            }

            .table-bottom-btn {
                a {
                    &.saved-control{
                        background: #4ec2ba;
                    }
                    color: #fff;
                    background: #b1bfcd;
                    padding: 7px 10px;
                    display: inline-block;
                    border-radius: 49px;
                    font-size: 14px;
                    max-width: 165px;
                    margin: 12px 0 5px;
                    width: 100%;
                    font-weight: 600;

                    span {
                        margin-right: 15px;
                    }
                }
            }
        }

        .evaluation-bottom-notice {
            text-align: center;
            margin-top: 40px;

            ul {
                margin-bottom: 0;

                li {
                    padding: 0 10px;
                    display: inline-block;
                    color: #7f8291;
                    font-size: 14px;

                    label {
                        font-weight: 600;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    &.print_view_page{
        width: 100%;
        float: none;
        padding: 0.5rem;
        margin: 2rem auto;
        .ph_top_left{
            background-color: #b1bfcd;
            border-radius: 100%;
            padding: 0;
            color: #fff;
            height: 35px;
            width: 35px;
            line-height: 35px;
            margin-top: 0.3rem;
            text-align: center;
            position: absolute;
        }
        .print_container{
            padding: 25px;
            table{
                border: 1px solid #d1dbe2;
                .header_table{
                    td{
                        border: 0;
                        border-bottom: 1px solid #d1dbe2;
                    }
                }
            }
        }
        .print_view_header{
            display: flex;
            flex-direction: column;
            align-items: center;
            .print_header_top{
                display: flex;
                align-items: flex-start;
                justify-content: center;
                width: 100%;
                .ph_top_left{
                    background-color: transparent;
                    border-radius: 100%;
                    padding: 0;
                    color: #fff;
                    height: 35px;
                    width: 35px;
                    line-height: 35px;
                    margin-top: 0.3rem;
                    text-align: center;
                }
                .ph_top_middle{
                    > label{
                        background: #fff;
                        padding: 0.4rem 4rem;
                        font-weight: 500;
                        font-size: 18px;
                        border-radius: 4px;
                    }
                    .ph_top_middle_bottom{
                        display: flex;
                        justify-content: space-around;
                        font-size: 0.8rem;
                        padding-right: 0;
                        margin-top: 8px;
                        font-weight: 500;
                        label{
                            color: #a7a7a7;
                        }
                    }
                }
                .ph_top_right{
                    margin-top: 0.4rem;
                    .btn{
                        padding: 0.4em 2rem;
                    }
                }
            }
        }
        .annual_reviews_container {
            background-color: transparent;
            padding: 0;
             .a_r_single_table {
                background: transparent;
                border: 0;
                padding: 0;
                table{
                    td{
                        text-align: center;
                        &:first-of-type{
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}

#exam_content {
    .e_single_table table tr td:first-of-type {
        width: 95%;
        @media (max-width: 1199px) {
            width: 90%;
        }
    }
}

/*******************modal- student list ************************/
.os-theme-dark>.os-scrollbar>.os-scrollbar-track>.os-scrollbar-handle {
    background-image: linear-gradient(181deg, #72dcd6, #3eb6ad) !important;
}

.os-theme-dark>.os-scrollbar,
.os-theme-light>.os-scrollbar {
    padding: 3px !important;
}

#e_student_list {
    .modal-dialog {
        max-width: 422px;

        .modal-content {
            border-radius: 12px;
            box-shadow: 0 5px 45px 0 rgba(47, 81, 144, 0.18);
            background-color: #ffffff;

            .modal-body {
                padding: 0px 25px 30px;

                .bar_search {
                    padding-bottom: 1.8rem;

                    .input-group {
                        border-radius: 30px;
                        overflow: hidden;
                        border: 1px solid #b1bfcd;

                        input {
                            height: 2.1rem;
                            border: none;
                            border-radius: 0;
                            background-color: #f8f8f8;
                            font-size: 0.9rem;
                        }

                        .input-group-append {
                            margin-left: -1px;

                            button {
                                border-radius: 0;
                                border: none;
                                box-shadow: none;
                                background-color: #f8f8f8;
                                padding: 0px 10px;
                            }
                        }
                    }
                }

                .e_list_wrap {
                    padding: 13px 22px 13px 15px;
                    height: 426px;

                    .os-content-glue {
                        height: 600px !important;
                    }

                    .os-content {
                        padding: 0px 9px 13px 0px !important;
                    }

                    ul {
                        list-style-type: none;
                        padding-left: 0px;

                        .e_list_student_item {

                            li {
                                padding: 0.6rem 8px 0.6rem;
                                border: 1px solid transparent;

                                img {
                                    border-radius: 7px;
                                }

                                span {
                                    font-size: 14px;
                                    color: #1b2437cc;
                                    margin-left: 19px;
                                }

                                &:hover {
                                    cursor: pointer;
                                    box-shadow: 0 5px 45px 0 rgba(47, 81, 144, 0.18);
                                    border: 1px solid rgba(47, 81, 144, 0.18);
                                    border-radius: 7px;
                                    // transition: all 1.5s ease-in;

                                    span {
                                        color: #1b2437;
                                        font-weight: 500;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:991px) {
    // header {
    //     width: 100% !important;

        // .navbar {
        //     padding-top: 0;
        //     padding-bottom: 0;

        //     .nav-item {
        //         .dropdown-menu {
        //             position: absolute;
        //             right: 0;
        //             left: auto;
        //         }
        //     }
        // }

        // &+aside {
        //     display: none;

        //     &.show_mobile_menu {
        //         display: block;
        //         z-index: 999;
        //         width: 100%;
        //         padding-top: 0;
        //         position: fixed;
        //         height: 100%;

        //         .mobile_menu_header {
        //             padding-top: 0;
        //             padding-bottom: 0;
        //             box-shadow: none;

        //             .nav_lang_link {
        //                 background-color: #D7DFE4;
        //                 border-radius: 100%;
        //                 padding: 0.687rem 0.6rem;
        //                 color: #fff;
        //                 font-size: 0.8rem;
        //             }

        //             .nav-item {
        //                 .dropdown-menu {
        //                     position: absolute;
        //                     right: 0;
        //                     left: auto;
        //                 }
        //             }
        //         }

        //         .side_items {
        //             max-height: 90%;
        //             overflow: auto;

        //             ul {
        //                 display: flex;
        //                 flex-wrap: wrap;
        //                 padding: 0 10px;

        //                 li {
        //                     flex-basis: 33.3%;
        //                     margin: 25px 0;

        //                     span {
        //                         font-size: 18px !important;
        //                     }

        //                     &.side_item.active {
        //                         background-image: none;

        //                         &::before {
        //                             width: 0;
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }

    //     .menu-toggle {
    //         background-color: #D7DFE4;
    //         border-radius: 100%;
    //         color: #fff;
    //         width: 34px;
    //         height: 34px;
    //         line-height: 36px;
    //         text-align: center;

    //         * {
    //             color: #fff;
    //         }
    //     }
    // }
    .mobile_table_popup {
        background: rgba(177, 191, 205, 0.5);
        .modal-content {
            .modal-header {
                padding-left: 30px;
                padding-bottom: 20px;

                .modal-title {
                    text-align: left;
                }
            }

            .modal-body {
                padding: 0px 35px 30px;
            }

            table tr td {
                padding: 5px 14px;

                &:first-of-type {
                    width: auto;
                }
            }
        }
    }
    
    div.main {
        /*width: calc(100% - 95px);
        padding:1.2rem;*/

        &.evaluation_page {
            .top_bar {
                background: transparent;

                .bar_right {
                    background: #fff;
                    flex-basis: 58%;
                    justify-content: space-between;

                    .bar_select {
                        max-width: 150px;
                        margin-right: 0.5rem;
                        padding-left: 5px;
                    }
                }

                .bar_left {
                    flex-basis: 42%;

                    .nav-item {
                        margin: 0;
                        flex-basis: 50%;

                        a {
                            padding: 0;
                        }

                        &:last-of-type img {
                            margin-left: 20px;
                        }
                        img{
                            height: auto;
                            width:auto;
                            max-width: 33px;
                        }
                    }

                    div {
                        align-items: center;
                        flex-basis: 67%;
                        background: #fff;
                        margin-right: 3px;

                        .bar_title {
                            font-size: 1rem;
                            margin: 0;
                            padding: 5px 10px;
                        }
                    }
                }
            }

            .evaluation_container {
                background-color: transparent;
                margin-top: 1.5rem;
                padding: 0;
                border-radius: 0;

                &#exam_content {
                    .e_single_table table tr td:first-of-type {
                        width: 90%;
                    }
                }

                .e_top_bar {

                    select,
                    label {
                        background-color: transparent;
                        box-shadow: none;
                    }

                    .e_top_left {
                        .student_info {

                            .student_name {
                                padding-left: 0;
                            }
                            .custom-dropdown{
                                width: 100%;
                                left: 0%;
                            }
                        }
                    }

                    .e_top_right {
                        margin-right: 0;
                        align-items: center;
                        .btn{
                            padding: 0.3rem 0.5rem;
                            margin-left: 4px;
                        }
                        button {
                            padding: 0.4rem 1.2rem;
                        }
                    }
                }

                .e_single_table table tr td:first-of-type {
                    width: 60%;
                }
            }
        }
        &.annual_reviews_page {
            .top_bar {
                background: transparent;

                .bar_right {
                    background: #fff;
                    flex-basis: 58%;
                    justify-content: space-between;

                    .bar_select {
                        max-width: 150px;
                        margin-right: 0.5rem;
                        padding-left: 5px;
                    }
                }
            }

            .annual_reviews_container {
                background-color: transparent;
                margin-top: 1.5rem;
                padding: 0;
                border-radius: 0;

                .e_top_bar {

                    select,
                    label {
                        background-color: transparent;
                        box-shadow: none;
                    }

                    .e_top_right {
                        margin-right: 0;
                        align-items: center;
                        .btn{
                            padding: 0.3rem 0.5rem;
                            margin-left: 4px;
                        }
                        button {
                            padding: 0.4rem 1.2rem;
                        }
                    }
                }
            }
        }
    }

    .float-raise-btn {
        a {
            position: fixed;
            bottom: 8%;
            left: 20px;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-image: linear-gradient(258deg, #fdd271, #d5a433);
            text-align: center;
            line-height: 55px;
            box-shadow: 0 15px 30px rgba(73, 73, 73, 0.5);

            &.float_right_plus {
                left: auto;
                right: 20px;
            }
        }
    }
}

@media only screen and (max-width:767px) {
    .test.select2-selection--single .select2-selection__arrow b{
        left: 150%;
    }
    .e_top_right{
        .bar_select{
            margin: 0 !important;
        }
    }
    .select2-container--default{
        max-width: 100%;
        min-width:unset!important;
        width: fit-content!important;
        .select2-selection--single{
            .select2-selection__rendered{
                   font-size: 1rem !important;
            } 
        }
    }
    div.main.evaluation_page {
        .top_bar {
            .bar_left {
                flex-basis: 100%;
                .nav {
                    width: 100%;
                    display: flex;
                    flex-wrap: nowrap;
                    div{
                        background-color: transparent;
                    }
                }
            }
        }

        .evaluation_container {
            .e_top_bar {
                display: block;

                @media only screen and (max-width:767px) {
                    display: flex;
                    .subject_select{
                        display: none !important;
                    }
                }
                .bar_select {
                    margin-right: 0rem;
                    background: transparent;
                    margin: 15px 0;
                    .select2-container--default{
                        .select2-selection--single{
                            background: transparent;
                        }
                    } 
                    @media only screen and (max-width:767px) {
                        // display: none !important;
                    }
                }

                .e_top_left {
                    display: block;

                    .student_info {
                        padding: 6px 0;
                    }

                    select {
                        flex: 0 150px;
                    }
                }

                label {
                    padding: 0;
                }

                .e_top_right {
                    // padding-top: 5px;
                    .select2-container--default{
                        max-width: 100px;
                        min-width: unset;
                        width: fit-content
                    } 

                    button {
                        padding: 0.4rem 0.5rem;
                        max-width: 106px;
                        width: 100%;
                    }
                }
            }

            &#exam_content .e_single_table table tr td:first-of-type {
                width: 85% !important;
            }

            &#exam_content .e_single_table table tr.std_view td:first-of-type {
                width: 80% !important;
            }

            .e_single_table table td,
            .evaluation_page .evaluation_container .e_single_table table th {
                padding: 15px 12px;
            }

            .e_single_table table tr {
                td:first-of-type {
                    width: 85%;
                    position: relative;

                    a {
                        position: absolute;
                        right: -2px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }

                td:last-of-type {
                    /*width: 17%;*/
                    font-size: 1rem;
                    padding: 10px;
                    position: relative;
                    @media (max-width: 767px) and (min-width: 500px) {
                        width: inherit;
                    }
                }

                &.show_slide_cells {
                    td:first-of-type {
                        font-size: 10px;
                    }

                    td.d-none {
                        display: table-cell !important;
                        width: 42%;
                    }

                    td:last-of-type {
                        width: 32%;
                    }
                }
            }
            &.parent_view{
                .e_single_table table tr {
                    td:first-of-type {
                        width: 85%;
                    }
                }
            }
        }
        &.print_view_page{
            width: 100%;
            padding:1.2rem;
            .print_view_header{
                .print_header_top .ph_top_right .btn {
                    padding: 0.4em 1rem;
                }
                .print_header_top .ph_top_middle > label {
                    padding: 0.3rem 1.5rem;
                    font-size: 17px;
                }
            }
            table{
                td{
                    color: #000;
                }
            }
        }
    }
    div.main.annual_reviews_page {

        .annual_reviews_container {
            .e_top_bar {
                display: block;

                @media only screen and (max-width:767px) {
                    display: flex;
                    .subject_select{
                        display: none !important;
                    }
                }
                .bar_select {
                    margin-right: 0rem;
                    background: transparent;
                    margin: 15px 0;
                    .select2-container--default{
                        .select2-selection--single{
                            background: transparent;
                        }
                    } 
                    @media only screen and (max-width:767px) {
                        // display: none !important;
                    }
                }

                .e_top_left {
                    display: block;

                    .student_info {
                        padding: 6px 0;
                    }

                    select {
                        flex: 0 150px;
                    }
                }

                label {
                    padding: 0;
                }

                .e_top_right {
                    // padding-top: 5px;
                    .select2-container--default{
                        max-width: 100px;
                        min-width: unset;
                        width: fit-content
                    } 

                    button {
                        padding: 0.4rem 0.5rem;
                        max-width: 106px;
                        width: 100%;
                    }
                }
            }

            .a_r_single_table table td,
            .annual_reviews_page .annual_reviews_container .a_r_single_table table th {
                padding: 15px 12px;
            }

            .a_r_single_table table tr {
                td:first-of-type {
                    position: relative;
                }

                td:last-of-type {
                    /*width: 17%;*/
                    font-size: 1rem;
                    padding: 10px;
                    position: relative;
                    @media (max-width: 767px) and (min-width: 500px) {
                        width: inherit;
                    }
                }

                &.show_slide_cells {
                    td:first-of-type {
                        font-size: 10px;
                    }

                    td.d-none {
                        display: table-cell !important;
                    }
                }
            }
        }
        &.print_view_page{
            width: 100%;
            padding:1.2rem;
            .print_view_header{
                .print_header_top .ph_top_right .btn {
                    padding: 0.4em 1rem;
                }
                .print_header_top .ph_top_middle > label {
                    padding: 0.3rem 1.5rem;
                    font-size: 17px;
                }
            }
            table{
                td{
                    color: #000;
                }
            }
        }
    }

    header+aside.show_mobile_menu .side_items ul li {
        flex-basis: 50%;
        margin: 13px 0;
    }
}

@media only screen and (max-width: 1199px) and (min-width: 950px) {
    div.main.evaluation_page {
        .top_bar {
            flex-wrap: wrap;
            .bar_left {
            }
            .bar_right {
                background: inherit;
                flex-basis: 60%;
                margin-left: 40%;
                margin-top: 10px;
                .bar_select{
                    max-width: 33%;
                    label{
                        background: inherit;
                    }
                }
            }
        }
    }
    div.main.annual_reviews_page {
        .top_bar {
            flex-wrap: wrap;
            .bar_right {
                background: inherit;
                flex-basis: 60%;
                margin-left: 40%;
                margin-top: 10px;
                .bar_select{
                    max-width: 33%;
                    label{
                        background: inherit;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 950px) and (min-width: 768px) {
    div.main.evaluation_page {
        .top_bar {
            flex-wrap: wrap;
            .bar_right {
                margin-top: 10px;
                background: inherit;
                flex-basis: 73%;
                margin-left: 27%;
                .bar_select{
                    max-width: 33%;
                    label{
                        background: inherit;
                    }
                }
            }
        }
    }
    div.main.annual_reviews_page {
        .top_bar {
            flex-wrap: wrap;
            .bar_right {
                margin-top: 10px;
                background: inherit;
                flex-basis: 73%;
                margin-left: 27%;
                .bar_select{
                    max-width: 33%;
                    label{
                        background: inherit;
                    }
                }
            }
        }
    }
}

span.select2-dropdown{
    width: max-content !important;
    min-width: 100px !important;
}


td.mobile_appreciations{
    padding: 0 !important;
    background: white;
    table{
      td{
        background: white !important;
        color: black !important;
        &.img{
          width: 10px !important;
          padding: 5px !important;
        }
      }
      a{
        top: 0 !important;
      }
    }
  }


  .custom_checkbox_style{
    padding-top: 5px;
    .pretty{
        .state{
            label{
                display: block;
                font-family: Montserrat;
                font-size: 15px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #1b2437;
                margin-bottom: 5px;
                &::after, &::before{
                    top: 0;
                }
            }
        }
    }
}