/************ Evaluation Sidebar *************/
body.side_opened {
    .evaluation_with_sidebar{
        .e_single_table table tr td:first-of-type {
            width: 62% !important;
        }
    }
}
.evaluation_main_grid{
    display: flex;
    justify-content: space-between;
    .evaluation-table-grid{
        flex-basis: 70%;
    }
    .evaluation-sidebar{
        flex-basis: 28%;
        background: #fff;
        box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
        position: relative;
        max-height: 676px;
        .sidebar_sliding_close {
            height: 50px;
            width: 50px;
            line-height: 50px;
            position: absolute;
            font-weight: 500;
            background: #b1bfcd;
            right: -22px;
            text-align: right;
            padding-right: 8px;
            border-radius: 100px;
            top: 0;
            bottom: 0;
            z-index: -1;
            margin: auto;
            color: #fff;
        }
        .nav-tabs {
            border: 0;
            .nav-link{
                border-radius: 3px;
                border:0px;
                font-size: 0.8rem;
                color: #1b2437;
                padding: 10px;
                &.active {
                    background-color: #fff;
                    box-shadow: 0 3px 17px 0 rgba(90, 202, 195, 0.5);
                    background-image: linear-gradient(253deg, #72dcd6, #3eb6ad);
                    color: #fff;
                }
                img{
                    margin-right: 5px;
                    margin-top: -2px;
                }
            }
        }
        .tab-content{
            padding: 20px !important;
            .single-statistics-block{
                display: flex;
                justify-content: space-between;
                margin-top: 20px;
                box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
                padding: 20px;
                .statistic-label{
                    font-size: 0.9rem;
                    font-weight: 600;
                    text-transform: uppercase;
                    span{
                        display: block;
                        font-size: 0.8rem;
                        font-weight: normal;
                        text-transform: none;
                    }
                }
                .statistic-count{
                    width: 45px;
                    height: 45px;
                    line-height: 45px;
                    text-align: center;
                    border-radius: 50%;
                    font-size: 18px;
                    font-weight: 500;
                    color: #fff;
                    @include green_grad;
                    @include green_shadow;
                    &.orange_grad{
                        @include orange_grad;
                        box-shadow: 0 0px 24px 0 rgba(255, 134, 125, 0.43);
                    }
                }
            }
        }
    }
    .evaluation-status-sidebar{
        padding: 10px;
        .evaluation-status-content{
            .eval-status-header{
                padding-top: 10px;
                padding-left: 60px;
                position: relative;
                padding-bottom: 20px;
                button{
                    @include modal_round_btns;
                    cursor: pointer;
                    position: absolute;
                    left: 0;
                    border: 0;
                    top: 0;
                }
                h4{
                    font-size: 14px;
                    color: #5a5a5a;
                    width: 100%;
                }
            }
            .bar_search {
	    		.input-group {
	    			border-radius: 30px;
				    overflow: hidden;
				    border: 1px solid #b1bfcd;

				    input {
				    	height: 1.7rem;
				    	border: none;
					    border-radius: 0;
					    background-color: #f8f8f8;
					    font-size: 0.9rem;

					    &:focus {
					    	box-shadow: none;
					    }

					    &::placeholder {
					    	color: #b1bfcd;
					    	font-size: 0.9rem;
					    }
				    }

				    button {
				    	border-radius: 0;
					    border: none;
					    box-shadow: none;
                        background-color: #f8f8f8;
                        padding: 0px 10px;
				    }
	    		}
	    	}

	    	.bar_ham {
	    		margin: 0 1.4rem;
            }
            .eval_sidebar_top_select{
                display: flex;
                justify-content: space-between;
                .bar_select {
                    label.input-group-text {
                        border: none;
                        background-color: #fff;
                        font-size: 0.8rem;
                        color: #c3c3c3;
                        padding-right: 0;
                    }
                    select {
                        border: none;
                        font-size: 0.8rem;
                        font-weight: 600;
                        box-shadow: none;
                        padding: 0 13px 0 6px;
                        background-position: right center;
                        text-align: center;
                    }
                }
            }
            .students-list-col{
                border-radius: 4px;
                padding: 0px 22px 0px 15px;
                margin-top: 30px;
                max-height: 430px;
                .os-content-glue{
                    height: 600px !important;
                }
                .single-students-list{
                    margin-bottom: 20px;
                }
                .students-list-header {
                    display: flex;
                    justify-content: space-between;
                    border-bottom: 1px solid #d6d9e0;
                    label {
                        font-size: 14px;
                        color: #5a5a5a;
                        font-weight: 600;
                    }
                }
                .students-list-content{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 1px solid rgba(214, 217, 224, 0.2);
                    padding: 9px 0;
                    label{
                        font-size: 14px;
                        color: #5a5a5a;
                        font-weight: 500;
                        margin-bottom: 0;
                    }
                    .student-list-left-con{
                        img{
                            height: 30px;
                            width: 30px;
                            border-radius: 5px;
                        }
                        label{
                            margin-left: 15px;
                        }
                    }
                }
            }
        }
    }
}
