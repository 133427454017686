@mixin green_grad() {
	background-image: linear-gradient(45deg, #3eb6ad, #72dcd6);
}
@mixin green_grad_important() {
	background-image: linear-gradient(45deg, #3eb6ad, #72dcd6) !important;
}

@mixin orange_grad() {
	background-image: linear-gradient(252deg, #ff867f, #ff5f04);
}

@mixin yellow_grad() {
	background-image: linear-gradient(258deg, #fdd271, #d5a433);
}

@mixin green_shadow() {
	box-shadow: 0 0 20px 0 rgba(90, 202, 195, 0.5);
}

@mixin yellow_shadow() {
	box-shadow: 0 5px 24px 0 rgba(239, 194, 91, 0.5);
}

@mixin orange_shadow() {
	box-shadow: 0 0px 24px 0 rgba(255, 134, 125, 0.43);
}

/************* Modal Mixins *************/
@mixin modal_outer_green_grad_shadow(){
	box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
}

@mixin modal_btns_shadow(){
	box-shadow: 0 5px 20px 0 rgba(90, 202, 195, 0.5);
}

@mixin modal_outer_style(){
	@include modal_outer_green_grad_shadow;
	border-radius: 20px;
	background-color: #ffffff;
	border: 0;
}

@mixin modal_round_btns(){
	@include green_grad;
	@include modal_btns_shadow;
	border-radius: 50%;
    opacity: 1;
    color: #fff;
    font-size: 18px;
    font-weight: normal;
    height: 45px;
    width: 45px;
    padding: 0;
	line-height: 40px;
	outline: none;
}

@mixin modal_common_btns(){
	@include modal_btns_shadow;
	@include green_grad;
	border-radius: 49.5px;
	color: #fff;
    min-width: 106px;
    padding: 6px 15px;
    font-size: 12px;
	font-weight: 600;
	outline: none;
	border: 1px solid transparent;
}

/************* Colors ***************/
$primary_green: #5acac3;
$primary_gray: #b1bfcd;
$text_gray: #b1bfcd;
$label_grey:#7f8291cc;

/************* Overrides ***************/
// button primary
div.evaluation_page{
	.btn {
		border-radius: 14px;
		color: #fff;
		border-radius: 40px;
		padding: 0.2rem 1.5rem;
		font-size: 0.8rem;
		border: none;

		&:hover {
			color: #fff;
		}

		&.btn-green {
			@include green_grad;
		}

		&.btn-gray {
			background-color: $primary_gray;
		}

		&.btn-yellow {
			@include yellow_grad;
			@include yellow_shadow;
		}

		&.btn-orange {
			@include orange_grad;
			@include orange_shadow;
		}
	}
}