/******** Common Styles **********/
.modal-backdrop{
	background: transparent;
}
.mar-top-40{
	margin-top:40px;
}
.os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
    background-image: linear-gradient(181deg, #72dcd6, #3eb6ad) !important;
}
.os-theme-dark > .os-scrollbar, .os-theme-light > .os-scrollbar{
	padding: 4px !important;
	&.os-scrollbar-horizontal{
		display: none;
	}
}
.modal.mobile_table_popup{
	.appreciation td > span.select2{
		width: 100% !important;
		max-width: 100% !important;
	}
	.modal-dialog {
		height: 100%;
		display: flex;
		align-items: center;
		// margin: 0 auto;
	}
	.modal-content{
		@include modal_outer_style;
		.modal-header{
			padding-right: 50px;
			border: 0;
			padding-top: 25px;
			padding-bottom: 50px;
			position: relative;
			button{
				@include modal_round_btns;
				cursor: pointer;
				position: absolute;
				left: -1px;
				border: 0;
				top: -1px;
				&.btn-right{
					left: auto;
					right:0;
				}
			}
			.modal-title{
				font-size: 14px;
				color: #1b2437;
				font-weight: 600;
				width: 100%;
				text-align: left;
			}
		}
		.modal-body{
			padding: 0px 35px 30px;
			.circ {
				height: 22px;
				width: 22px;
				border-radius: 100%;
				margin: 0 auto;
				display: inline-block;
	
				&.green {
					background-image: linear-gradient(223deg, #72dcd6, #3eb6ad);
				}
	
				&.yellow {
					background-image: linear-gradient(220deg, #fdd271, #d5a433);
				}
	
				&.orange {
					background-image: linear-gradient(41deg, #ff5f04, #ff867f);
				}

				&.gray {
					background-image: linear-gradient(41deg, #90a1b1, #b1bfcd);
				}
	
				&.white {
					background-image: linear-gradient(41deg, #fff, #fff);
				}
			}
	
			.star {
				background-image: url('../img/evaluation/star_icon.svg');
				height: 22px;
				width: 22px;
				display: block;
				margin: 0 auto;
				background-size: contain;
				background-position: center;
	
				&.white {
					background-image: url('../img/evaluation/star_white_icon.svg');
				}
			}
			.e_single_table {
				background: rgba(245, 248, 249, 0.3);
				padding: 0px 10px 10px;
				
				>label {
					font-size: 14px;
					color: #5a5a5a;
					font-weight: 600;
				}
				//
				.e_single_table_wrap{
					border-radius: 0px;
					table{
						border: 1px solid #d1dbe2;
					}
					.classe_average_tr{
						@media (max-width: 768px){
							td:first-of-type{
								width: 85% !important;
							}
						}
						@media (min-width: 768) and (max-width: 1199px){
							td:first-of-type{
								width: 90% !important;
							}
						}
					}
				}
				table {
					border-radius: 8px;
					border: 1px solid #d1dbe2;
					font-size: 14px;
					color: rgba(27, 36, 55, 0.5);
					font-weight: 500;
					table-layout: fixed;
					border-collapse: collapse;
					width: 100%;
					border-radius: 8px;
	
					input {
						font-weight: bold;
						width: 100%;
						border: 0;
						text-align: center;
						&::-webkit-inner-spin-button {
							-webkit-appearance: none;
						}
					}
					tr:last-of-type{
						td, th{
							border-bottom:0;
						}
					}
	
					tr td {
						border-right: 1px solid #d1dbe2;
						border-bottom: 1px solid #d1dbe2;
						padding: 1px 4px;
						background: #fff;
						color: #adadad;
						background-clip: padding-box;
						font-size: 0.8rem;
						height: 47px;
	
						&:not(:first-child):not(:last-child) {
							color: #333;
							font-weight: bold;
						}
	
						// &:last-of-type, &.gradient_block {
						// 	text-align: center;
						// 	@include green_grad;
						// 	// background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
						// 	color: #fff !important;
						// 	border-right:0;
						// }
	
						&.grey_grad {
							background: #b1bfcd;
						}
	
						&.yellow_grad {
							@include yellow_grad;
							color: #fff !important;
							text-align: center;
						}
	
						&.orange_grad {
							@include orange_grad;
						}
						.btn-secondary{
							border: 0 !important;
						}
                        &.info_message{
                            width: 100% !important;
                            background: transparent !important;
                            text-align: left !important;
                            color: #b0bfcd !important;
                        }
					}
	
					select {
						border: 0;
						background-color: transparent;
						padding: 0px 12px 0 0px;
						outline: none;
						background-position: right center;
						box-shadow: none !important;
						font-weight: 700;
						font-size: 17px;

						text-align: center;
						//background-image: url('../../assets/images/evaluation/evaluation-select-arrow.svg');
					}
	
					.e_dropdown {
						text-align: center;
						position: relative;
	
						span{
							display: inline-block;
							vertical-align: middle;
							width: 22px;
							height: 22px;
						}
	
						.down_arrow {
							//background-image: url('../../assets/images/evaluation/evaluation-select-arrow.svg');
							position: absolute;
							z-index: 90;
							right: -10px;
							top: 40%;
							width: 10px;
							height: 5px;
						}
	
						.dropdown-toggle {
							padding: 0;
							background-color: transparent;
	
							&::after {
								content: unset;
							}
	
							&:focus {
								box-shadow: none;
							}
	
						}
	
						.dropdown-menu {
							min-width: 2.5rem;
	
							.dropdown-item {
								padding: .25rem 0;
								display: flex;
							}
						}
					}
				}
	
				.table-bottom-btn {
					a {
						color: #fff;
						background: #b1bfcd;
						padding: 7px 10px;
						display: inline-block;
						border-radius: 49px;
						font-size: 14px;
						max-width: 165px;
						margin: 12px 0 5px;
						width: 100%;
						font-weight: 600;
	
						span {
							margin-right: 15px;
						}
					}
				}
			}
			.action_evaluation_mobile{
				text-align: center;
				margin-top: 10px;
				button{
					background-image: linear-gradient(45deg, #3eb6ad, #72dcd6);
					box-shadow: 0 5px 20px 0 rgba(90, 202, 195, 0.5);
					color: white;
					border: 0;
					padding: 5px 15px;
					border-radius: 15px;
				}
			}
			form{
				label{
					color: #1b2437;
					font-size: 14px;
				    margin-bottom: 7px;
					display: block;
					font-weight: 500;
				}
				input[type='text'], select{
					width: 100%;
					border-radius: 2.6px;
					background-color: rgba(245, 248, 249, 0.3);
					border: 0.6px solid #d1dbe2;
					font-size: 13px;
					height: 28px;
					padding: 2px 6px;
					outline: none !important;
					font-weight: 500;
				}
				select{
					//background-image: url('../img/modal/select_arrow.svg');
					background-position: 98% center;
					background-repeat: no-repeat;
					-moz-appearance: none;
					-webkit-appearance: none;
					&::-ms-expand {
						display: none;
					}
				}
				.create_group_select_group{
					border: 1px solid rgba(151, 151, 151, 0.2);
					border-radius: 4px;
					background-color: rgba(245, 248, 249, 0.3);
					padding: 22px 15px 20px;
					display: flex;
					justify-content: space-between;
					.single-block{
						border-radius: 8px;
						box-shadow: 2px 3px 10px 0 rgba(0, 0, 0, 0.1);
						background-color: #ffffff;
						position: relative;
						flex-basis: 24%;
						border-bottom: 5px solid transparent;
					    display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						input[type='checkbox']{
						    width: 100%;
							height: 100%;
							background: transparent;
							border: 0 !important;
							position: absolute;
							left: 0;
							top: 0;
							visibility: hidden;
						}
						.checkbox-middle-block{
							display: flex;
							border-bottom: 1px solid rgba(151, 151, 151, 0.2);
							align-items: center;
							padding: 28px 12px 24px;
							img{
								border-radius: 50%;
								height: 30px;
								width: 31%;
								margin-right: -8px;
							}
							a{
								background: #f5f8f9;
								text-align: center;
								line-height: 28px;
								border-radius: 50%;
								color: #1b2437;
								font-size: 12px;
								height: 30px;
								width: 31%;
								border: 1px solid #d1dbe2;
							}
						}
						.checkbox-footer{
							padding: 11px 15px;
							h4{
							    font-size: 14px;
								color: #1b2437;
								font-weight: 600;
								margin-bottom: 10px;
							}
							h5{
								margin: 0;
								font-size: 12px;
								color: #1b2437
							}
						}
						&.selected{
							border-bottom: 5px solid #ec735b;
							&:before {
								content: "\f00c";
								position: absolute;
								font-family: "Font Awesome 5 Free";
								left: 0;
								right: 0;
								margin: auto;
								height: 32px;
								font-size: 14px;
								width: 32px;
								top: -16px;
								font-weight: 600;
								text-align: center;
								background-image: linear-gradient(45deg, #72dcd6, #3eb6ad);
								color: #fff;
								border-radius: 50%;
								line-height: 32px;
								box-shadow: 0 4px 20px 0 rgba(90, 202, 195, 0.5);
							}
						}
						&.btn-block{
							button{
							    margin: 0;
								min-width: 92px;
								background: #fda800;
								box-shadow: none;
								text-align: left;
								span{
									margin-right: 10px;
								}
							}
						}
					}
				}
				input[type='submit'], button{
					@include modal_common_btns;
					text-transform: uppercase;
					margin: 20px auto 0;
					display: block;
					&.grey-border-btn{
						border-color: #d1dbe2;
						color: #3eb6ad;
						background: rgba(245, 248, 249, 0.3); 
						box-shadow: none;
					}
				}
			}
		}


		.modal-footer{
			justify-content: center;
			border: 0;
			padding-bottom: 35px;
			button{
				@include modal_common_btns;
				text-transform: uppercase;
				&.grey-border-btn{
					border-color: #d1dbe2;
					  color: #3eb6ad;
					  background: none; 
					  box-shadow: none;
				}
			}

			&.steps {
				position: absolute;
			    left: 18px;
			    bottom: 0;
				font-weight: 500;
			    font-size: 11px;
			    color: #a7b7bc;
			}
		}
	}
	&.apprs{
		.modal-dialog{
			height: calc(100% - 3.5rem);
			min-height: 430px;
			max-width: 600px;
			.modal-content{
				height: 100%;
				max-height: 490px;
			}
			.modal-body{
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding-top: 20px;
				td .appreciation_input{
					height: 140px !important;
				}
			}
		}
	}

}

/************ Create Group Challenge **************/
#create_group_challenge{
	.modal-dialog{
		max-width: 620px;
		.modal-body{
			padding-left: 30px;
			padding-right: 30px;
			form{
				.create_group_first_form{
					padding: 0 15px;
					+ .form-group{
						margin-bottom: 30px;
						label{
							padding-bottom: 10px;
							padding-left: 10px;
						}
					}
				}
			}
			.create_group_select_group{
				padding-bottom: 60px;
			}
		}
	}
}
/******** Schedule view**********/
#create_class_challenge {
	.modal-body{
		padding-bottom: 1rem;
		form{
			.form-row{
				justify-content: space-between;
				margin: 0;
			}
			.creat_class_upper_select{
				margin-bottom: 40px;
			}
			.select-class-wrap{
				display: flex;
    			flex-wrap: wrap;
    			justify-content: space-between;
    			background-color: #fcfcfd;
			    padding: 13px 14px 14px;
			    border: 1px solid #d1dbe2;
			    border-radius: 4px;
			    margin-top: 11px;
    			.select-class {
					flex : 0 0 31.2%;
					&:last-of-type label, &:nth-last-of-type(2) label, &:nth-last-of-type(3) label{
						margin-bottom:0;
					}
					label{
						border:1px solid;
						padding:5px;
						margin: 0px 0px 12px;
						display:block;
						text-align: center;
						border-radius: 2.6px;
						font-size: 0.8rem;
						border-color: #d1dbe2;
						background: #f9f9fb;
						
						&:hover, &.selected {
							cursor: pointer;
							border-color:#3eb6ad;
						}
					}
					input[type=checkbox] {
						visibility: hidden;
						position: absolute;

						&:checked + label {
							background-color: #333;
						}
					}
    			}
			}
			.radio-btn-class{
				input{
					margin-right: 10px;
					vertical-align: middle;
					height: 20px;
					width: 20px;
				}
			}

		}
	}

	.modal-footer {
		padding-bottom: 20px;
		margin-top: 30px;
	}
}
/******** Create Group - Select Users ********/
.create_group_challenge_two {
	.modal-dialog{
		max-width: 620px;
	}

	form {
		input {
			width: 40% !important;
		}
	}
	.create_group_select_users {
		display: flex;
		flex-wrap: wrap;
		height: 250px;
		overflow-y: auto;
		background: rgba(245, 248, 249, 0.3);
		border: 1px solid #d1dbe2;
		padding: 10px;
		border-radius: 4px;
		.os-content{
			display: flex;
			flex-wrap: wrap;
		}
		.single_user {
			flex-basis: 16.666%;
		    padding: 10px;
		    text-align: center;
		    cursor: pointer;

		    .user_image {
		    	border-radius: 100%;
			    width: 70px;
			    overflow: hidden;
			    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.3);

			    img {
			    	width: 100%;
			    }
		    }

		    &.selected {
		    	.user_image {
		    		overflow: hidden;
		    		position: relative;
					box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.3) !important;
		    		&::after {
					    font-size: 2em;
					    font-weight: 900;
					    vertical-align: middle;
					    font-family: "Font Awesome 5 Free";
		    			content: '\f00c';
					    position: absolute;
					    top: 0;
					    left: 0;
					    width: 100%;
					    height: 100%;
					    padding-top: 12px;
					    color: $primary_green;
					    background-color: #fff;
		    		}

			    	img {
			    		opacity: 0;
		    		    z-index: -1;
			    	}
		    	}
		    }

		    span {
		    	font-size: 0.8rem;
				line-height: 1.2;
				display: block;
				margin-top: 0.5rem;
				font-weight: 500;
		    }
		}
	}

	.modal-footer{
	    font-weight: 500;
		font-size: 11px;
		color: #a7b7bc;
		position: absolute;
		left: 8px;
		bottom: 0;
	}
}

/********challenge_one**********/
#challenge_one{
	.modal-dialog{
		max-width: 708px;
		.modal-body{
			margin-bottom: 40px;
		}
		.btn{
			&.grey-border-btn{
				border-color: #d1dbe2;
				color: #3eb6ad;
				background: rgba(245, 248, 249, 0.3); 
				box-shadow: none;
			}
		}
		.challenge_one_wrap{
			display: flex;
	    	flex-wrap: wrap;
	    	justify-content: space-between;
	    	text-align: left;
	    	.challenge_one_iteam{
	    		flex : 0 0 48.2%;
	    		// padding: 15px 11px;
	    		margin-bottom: 12px;
				border-radius: 4px;
	  			box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
	  			background: #fff;
	    		img{
	    		    position: absolute;
					left: 21px;
					top: 0;
					bottom: 0;
					margin: auto;
					max-width: 30px;
	    		}

	    		a{
	    			color: #1b2437;
				    text-decoration: none;
				    display: block;
				    font-size: 14px;
				    color: #1b2437cc;
					font-weight: 500;
					padding: 21px 11px;
					padding-left: 65px;
					position: relative;
	    		}
	    	}
		}
	}
}

/************** Create Group Classes with chapter selection ***************/
.chapter-common-wrap{
	.modal-dialog{
		max-width: 620px;
		.form-submit-btns .btn {
			display: inline-block !important;
			margin-right: 10px;
		}
		.os-scrollbar-horizontal{
			display: none;
		}
		.modal-footer{
		    font-weight: 500;
			font-size: 11px;
			color: #a7b7bc;
			position: absolute;
			left: 8px;
			bottom: 0;
		}
	}
	.select-classes-block{
		border-radius: 4px;
		background-color: rgba(245, 248, 249, 0.3);
		border: 1px solid #d1dbe2;
		padding: 14px 22px;
		max-width: 76%;
		margin: auto;
		max-height: 145px;
		overflow-y: scroll;
		margin-top:20px;
	}
	.challenge_one_wrap{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		text-align: left;
		.challenge_one_iteam{
			flex : 0 0 48.2%;
			margin-bottom: 8px;
			border-radius: 4px;
			box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
			background: #fff;
			img{
				margin-right: 15px;
				// max-height: 24px;
			}
			a{
				text-decoration: none;
				display: block;
				padding-left: 6px;
				font-size: 14px;
				color: #1b2437cc;
				font-weight: 500;
				padding: 7px 11px;
				border: 1px solid transparent;
				border-radius: 4px;
				&:hover, &.selected{
					border-color: #3eb6ad;
				}
			}
		}
	}
}

/************** Create Single Class with chapter selection ***************/
#create_class_chapter{
	.modal-dialog{
		max-width: 470px;
		.select_classes_mar_bot label{
			margin-bottom: 15px;
		}
		.select-classes-block{
			max-width: 100%;
			.os-content{
				padding: 12px 14px !important;
			}
		}
	}
}
/*************** Challenge Five Modal ****************/
.challenge_five{
	.modal-dialog{
		max-width: 70%;
		.modal-body{
			padding-left: 10px;
			.challenge-details-wrap{
				display: flex;
				justify-content: space-between;
				.groups-list-col, .students-list-col{
					flex-basis: 59%;
					max-height: 470px;
					overflow-y: auto;
				}
				.groups-col-details{
					justify-content: space-between;
				    display: flex;
				    width: 100%;
				    align-items: center;
				}
				.groups-list-col{
					padding-right: 25px;
					padding-top: 20px;
					padding-left: 30px;
					.groups-col{
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding: 14px 12px;
						position: relative;
						background: #fff;
						margin-bottom: 20px;
						border-radius: 0px;
						&.active{
							box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
							&:after {
								content: '';
								border-left: 12px solid #ffffff;
								border-bottom: 12px solid transparent;
								border-top: 12px solid transparent;
								position: absolute;
								right: -11px;
							}
						}
						.number-of-students{
							background: rgba(0, 0, 0, 0.46);
							color: #fff;
						    font-size: 11px;
							padding: 2px 13px;
							border-radius: 14px;
							margin-right: 5px;
						}
						&.active{
							.students-list-col{
								display: block;
							}
						}
					}
				}
				.students-list-col{
					background: rgba(245, 248, 249, 0.3);
					border: 1px solid #d1dbe2;
					border-radius: 4px;
					padding: 13px 22px 13px 15px;
					flex-basis: 40%;
					min-height: 480px;
					display: none;
					.os-content-glue{
						height: 600px !important;
					}
					.single-students-list{
						margin-bottom: 20px;
					}
					.students-list-header {
						display: flex;
						justify-content: space-between;
						border-bottom: 1.5px solid #e2e4e9;
						label {
							font-size: 14px;
							color: #1b2437;
							font-weight: 600;
						}
					}
					.students-list-content{
						display: flex;
						justify-content: space-between;
						align-items: center;
						border-bottom: 1px solid rgba(214, 217, 224, 0.2);
						padding: 10px;
						label{
							font-size: 14px;
							color: #1b2437;
							font-weight: 500;
							margin-bottom: 0;
						}
						.student-list-left-con{
							img{
								height: 30px;
								width: 30px;
								border-radius: 5px;
							}
							label{
								margin-left: 15px;
							}
						}
					}
				}
			}
		}
		.checkbox-middle-block{
			display: flex;
			align-items: center;
			label{
			    font-size: 14px;
				font-weight: 600;
				color: #1b2437;
				margin-left: 14px;
				margin-bottom: 0;
			}
			img{
				border-radius: 50%;
				height: 33px;
				width: 33px;
				margin-right: -8px;
				box-shadow: 0 2px 15px 0 rgba(46, 104, 208, 0.1)
			}
			a{
				background: #f5f8f9;
				text-align: center;
				line-height: 33px;
				border-radius: 50%;
				color: #1b2437;
				font-size: 14px;
				height: 33px;
				width: 33px;
				border: 1px solid #d1dbe2;
			}
		}
	}
}

/******************* Challenge Results *****************/
#challenge_results{
	.modal-dialog {
		.challenge-details-wrap .groups-list-col .groups-col{
			border: 1px solid transparent;
			&.active{
				&:after{
					content: "";
				    border-left: 9px solid #ffffff;
				    border-bottom: 9px solid transparent;
				    border-top: 9px solid transparent;
				    position: absolute;
				    right: -9px;
				}
			}
		}
	}
}

/******************* Students List *****************/
#students_list_modal{
	.modal-dialog{
		max-width: 420px;
	}
	.modal-content{
		.modal-header {
			padding-left: 40px;
			text-align: left;
			padding-top: 20px;
			padding-bottom: 1rem;
			.modal-title{
				text-align: left;
				font-size: 16px;
				font-weight: 500px;
			}
		}
		.modal-body{
			max-height: 430px;
			padding:0px 35px 30px;
			// max-height: 200px;
			overflow: auto;
			.os-scrollbar-vertical{
				right: 12px;
			}
		}
		.form-group {
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid #d6d9e0;
			margin: 0;
			padding: 11px 0;
			.students-list-left-col{
				margin: 0;
				color: #1b2437;
				font-size: 14px;
				display: block;
				font-weight: 500;
				width:100%;
				img{
					width: 30px;
					height: 30px;
					border-radius: 5px;
					margin-right: 14px;
				}
				.pretty{
					width: 100%;
					margin-right: 0;
					input:checked~.state label:after {
						background-color: #51c4bc!important;
					}
					.state label{
						text-indent: 0;
						font-weight: 500;
						&:after, &:before{
							right: 0;
							top: 0;
							bottom: 0;
							margin: auto;
							left: auto;
							background-color: #cccccc4a;
							width: 17px;
    						height: 17px;
						}
						&:after{
							transform: scale(.7);
						}
					}
				}
			}
			.students-list-right-col{
				position: relative;
			}
			&.round {
				position: relative;
				padding-left: 8px;
				input[type="checkbox"] {
					&:checked + label {
						background-color: #51c4bc;
						border-color: #51c4bc;
					}
				}
			}
		}
		.modal-footer{
			padding-bottom: 20px;
			box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
			background-color: rgba(255, 255, 255, 0.7);
			button{
				@include yellow_grad;
				@include yellow_shadow;
			}
		}
	}
}
.pretty.p-default input:checked~.state label:after {
    background-color: #51c4bc!important;
}

/*************************/
#enter_group_name{
	.modal-dialog{
		max-width: 840px;
		.os-content{
			padding-right: 12px !important;
		}
		.enter_group_name_last_group{
			padding-bottom: 20px;
			padding-top: 20px;
		}
		.create_group_select_users{
			height: 370px;
			border: 0;
			background: transparent;
			padding: 0;
			// padding:0 20px;
			.os-content{
				display: flex;
				flex-wrap: wrap;
			}
			.single_user {
				flex-basis: 12.5%;
				//.user_image{
					// box-shadow: 0 4px 34px 0 rgba(46, 104, 208, 0.1);
				//}
			}
		} 
		.modal-footer.steps {
			left: 34px;
		}
	}
}
#creat_group_challenge_two{
	.modal-body{
		padding:0 30px 30px;
		.single_user.selected .user_image::after{
			padding-top: 9px;
		}
		.creat_group_first_group{
			padding: 0 12px 5px;
		}
		.creat_group_last_group{
			margin-top: 30px;
			label{
				padding: 0 12px 5px;
			}
		}
	}
	.single_user .user_image {
		width: 64px;
	}
}

/************** Responsive **************/
@media only screen and (max-width: 991px){
	#enter_group_name{
		.modal-dialog {
		    max-width: 750px;
		}
	} 
	.modal{
		.modal-content{
			.modal-body{
				form{
					input[type='text'], select{
						height: 38px;
					}
				}
			}
		}
	}
	#create_group_challenge .modal-dialog .modal-body{
		padding-right: 25px;
		padding-left: 25px;
		.create_group_select_group{
			padding-bottom: 30px;
		}
	} 
	.challenge_five{
		.modal-dialog{
			.modal-header{
				padding-bottom: 20px;
			}
			.modal-body {
				padding:0 0 40px;
				.challenge-details-wrap{
					flex-direction: column;
					.groups-list-col{
						padding-top: 30px;
						.groups-col{
							flex-direction: column;
							border: 1px solid rgb(177,191,205) !important;
							border-radius: 4px !important;
							&.active:after{
								display: none
							}
							.students-list-col {
							    flex-basis: 100%;
							    width: 100%;
							    margin-top: 30px;
							    //max-height: 300px;
								min-height: auto;
								.os-co
								ntent-glue {
								    height: 390px !important;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 767px){
	.modal.mobile_table_popup{
		.modal-dialog{
			max-width: 100%;
			.modal-content {
				max-height: 95%;
				overflow: auto;
				height: 300px;
				.modal-header{
					padding-bottom: 20px;
				}
				.modal-body{
					overflow: auto;
					padding-top: 50px !important;
					form{
						select{
							height: 38px;
						}
						.create_group_select_group{
							flex-wrap: wrap;
							.single-block{
								flex-basis: 47%;
								margin:20px 0;
							}
						}
					}
				}
				.modal-footer.steps{
					position: initial;
				}
			}
		}
	}
	// .modal{
	// 	.modal-dialog{
	// 		max-width: 100%;
	// 		.modal-content {
	// 			max-height: 95%;
	// 			overflow: auto;
	// 			.modal-header{
	// 				padding-bottom: 20px;
	// 			}
	// 			.modal-body{
    // 				overflow: auto;
	// 				form{
	// 					select{
	// 						height: 38px;
	// 					}
	// 					.create_group_select_group{
	// 						flex-wrap: wrap;
	// 						.single-block{
	// 							flex-basis: 47%;
	// 							margin:20px 0;
	// 						}
	// 					}
	// 				}
	// 			}
	// 			.modal-footer.steps{
	// 				position: initial;
	// 			}
	// 		}
	// 	}
	// }
	#create_class_challenge{
		.modal-content{
			.modal-header{
				padding-bottom: 30px;
			}
			.modal-body {
			    padding: 0px 15px 30px;
			    .creat_class_upper_select {
				    padding: 0 20px;
				}
				.select-class-wrap .select-class label {
				    padding: 3px 5px;
				}
			}
			.modal-footer{
				padding-bottom:0;
			}
		}
	}
	#challenge_one{
		.modal-content{
			.modal-body {
				padding:0 25px 20px;
				margin-bottom: 0;
				.challenge_one_wrap {
					.challenge_one_iteam{
						flex: 100%;
					}
				}
			}
		}
	}
	.challenge_five {
		.modal-dialog {
			.modal-body {
				.challenge-details-wrap .groups-list-col{
					padding-right: 15px;
					padding-left: 15px;
					max-height: 100%;
				}
				.checkbox-middle-block{
					img {
					    height: 28px;
					    width: 28px;
					}
					a{
						height: 28px;
    					width: 28px;
    					line-height: 28px;
					}
					label{
						font-size: 11px;
					    margin-left: 6px;
					}
				} 
				.challenge-details-wrap .groups-list-col .groups-col .number-of-students {
				    font-size: 9px;
				    padding: 2px 9px;
				    margin-right: 0px;
				}
			}	
		}
	}
	#create_class_chapter{
		&.modal{
			.modal-content{
				.modal-header{
					padding-bottom: 30px;
				}
				.modal-body{
					padding: 0px 30px 30px;
					.select_classes_mar_bot{
						margin-right: -15px;
					    margin-left: -15px;
					    margin-top: 30px;
					}
					.select-classes-block{
						max-height: 230px;
						.challenge_one_wrap .challenge_one_iteam{
							flex: 100%;
							a{
								display: flex;
    							align-items: center;
							}
						}
					}
				}
			}
		}
	}
	#students_list_modal{
		.modal-content{
			overflow: hidden;
			.modal-header{
				padding-top: 40px;
			}
			.modal-body{
				padding:0px 35px 30px 20px;
				max-height: 320px;
			}
		}
	}
	#create_group_chapter{
		.modal-content{
			.modal-header{
				padding-bottom: 30px;
			}
			.modal-body{
				padding: 0px 25px 30px;
				.select-classes-block{
					max-width: 100%;
					max-height: 230px;
					.challenge_one_wrap .challenge_one_iteam{
						flex: 0 0 100%;
					}
				}
			}
			.modal-footer{
				padding: 10px 0 20px;
			    position: initial;
			}
		}
	}
	#enter_group_name{
		.modal-content{
			.modal-header{
				padding-bottom: 30px;
			}
			.modal-body{
				padding:0 30px 30px;
				input{
					width: 100% !important;
					height: 38px;
				}
				.create_group_select_users .single_user{
					&.selected .user_image{
						box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.2) !important;
					}
					.user_image {
					    box-shadow: 0 2px 20px 0 rgba(46,104,208,0.1);
					    width: 70px;
					}
				}
			}
		}
	}
	#creat_group_challenge_two{
		.modal-body{
			padding:0 25px 30px;
			.single_user{
				flex: 0 0 33%;
			    // flex-grow: 1;
			    // flex-basis: 0;
				&.selected .user_image::after {
				    padding-top: 0px;
    				font-size: 22px;
    				display: flex;
    				align-items: center;
    				justify-content: center;
				}
				.user_image{
					width: 100%;
				}
			}
		}
	}
	.create_group_challenge_two .create_group_select_users .os-content{
		justify-content: space-between;
	}
}